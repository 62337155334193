import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
   AccordionBody,
   AccordionHeader,
   AccordionItem,
   Col,
   Container,
   Row,
   UncontrolledAccordion,
} from "reactstrap"

import { GetPackagesListing } from "api/api.service"
import { Loader } from "components/Loader"
import { ConfiguratorStatus } from "constants"
import ImageGallery from "pages/Configurator/CommonUI/ImageGallery/ImageGallery"
import SliderCard from "pages/Configurator/CommonUI/SliderCard/SliderCard"

const Packages = () => {
   let { trailerId } = useParams()
   const [packageData, setPackageData] = useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      getPackages()
   }, [trailerId])

   const getPackages = async () => {
      try {
         setLoading(true)
         let res = await GetPackagesListing({ trailerId: trailerId, limit: -1 })
         setPackageData(res)
      } catch (error) {
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loader />
   }

   if (packageData.total < 1) {
      return (
         <div className="d-flex justify-content-center align-items-center">
            <h2 className="p-4">No Data</h2>
         </div>
      )
   }

   return (
      <UncontrolledAccordion defaultOpen={0} className="my-4">
         {packageData?.data?.map((pkgObj, ind) => (
            <AccordionItem key={ind}>
               <AccordionHeader targetId={ind}>
                  <div className="accordion-title-container d-flex gap-4">
                     <h4 className="accordion-title-blue  p-0 m-0">
                        {pkgObj.packageName}
                     </h4>
                     <h4 className="accordion-title-black p-0 m-0">
                        ${pkgObj.price}
                     </h4>
                     <div
                        className={`configurator-status ${ConfiguratorStatus.getLabelClass(
                           pkgObj.status
                        )}`}
                     >
                        <span>
                           {ConfiguratorStatus.getDisplayTextKey(pkgObj.status)}
                        </span>
                     </div>
                     <div
                        className={`configurator-status configurator-package-customizable`}
                        style={{ width: "fit-content" }}
                     >
                        <span>
                           {pkgObj.isCustomizable
                              ? "Customizable"
                              : "Not Customizable"}
                        </span>
                     </div>
                  </div>
               </AccordionHeader>
               <AccordionBody accordionId={ind}>
                  <h4 className="accordion-title-black">Description</h4>
                  <div
                     className="quill-description-readonly"
                     dangerouslySetInnerHTML={{
                        __html: pkgObj.description,
                     }}
                  />
                  {/* <p>{pkgObj.description}</p> */}
                  <h4 className="accordion-title-black">Images</h4>

                  <ImageGallery
                     previousImages={pkgObj.images}
                     previewOnly
                     isDetailView
                  />
                  <Container fluid={true} className="p-0 mt-2">
                     <h4 className="accordion-title-black">Package Items</h4>
                     <Row>
                        {pkgObj?.items?.map((item, index) => (
                           <Col
                              key={index}
                              xs="12"
                              md="6"
                              xl={4}
                              className="mb-3"
                           >
                              <SliderCard
                                 price={item?.itemPrice}
                                 name={item?.item?.itemName}
                                 images={item?.images}
                                 isDefault={false}
                              />
                           </Col>
                        ))}
                     </Row>
                  </Container>
               </AccordionBody>
            </AccordionItem>
         ))}
      </UncontrolledAccordion>
   )
}

export default Packages

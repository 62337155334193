import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
   GetEquipmentCategoryItemDetails,
   AddEquipmentCategoryItems,
   UpdateEquipmentCategoryItems,
   GetItemsListing,
} from "../../../api/api.service"
import {
   initializeMediaUtil,
   uploadOnS3,
   finalizeMediaUtil,
} from "../../../utils/mediaUtils"
import {
   ADD_NOTE,
   FIELDS_NAME,
   VARIATIONS,
   initialValues,
   validationSchema,
} from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { getUID } from "../../../utils/commonUtils"
import {
   EquipmentCategoryItemsStatus,
   ConfiguratorEquipmentItemSelectionType,
} from "../../../constants"
import crossIcon from "../../../assets/images/cross-red.png"
import addIcon from "../../../assets/images/add-icon.svg"
import FormSkeleton from "../../../components/FormSkeleton/FormSkeleton"
import useAsyncSelect from "hooks/useAsyncSelect"
import VariationItemsModal from "./VariationItemsModal"
import DefaultItemModal from "./DefaultItemModal"
import ReactQuill from "react-quill"

const TITLES = {
   MODAL_ADD_TITLE: "Add new item",
   MODAL_UPDATE_TITLE: "Update item",
   MODAL_DETAILS_TITLE: "Item details",
   ADD_BTN_TEXT: "Save",
   UPDATE_BTN_TEXT: "Update",
   DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
   return status == EquipmentCategoryItemsStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
   let res = await UpdateEquipmentCategoryItems(id, data)
   toast.success("Item has been updated successfully !")
}

const STRUCTURE_VARIATION_ITEM = {
   dbId: "",
   defaultItem: {
      name: "",
      checked: false,
   },
   variationName: {
      name: "",
      value: "",
      errorMsg: "",
   },
   variationPrice: {
      name: "",
      value: "",
      errorMsg: "",
   },

   variationImage: {
      previousImages: [],
      newImageId: "",
   },

   variationItems: [],
}

const ManageEquipmentCategoryItem = ({
   isDetailView = false,
   isOpen = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const variationImagesRef = useRef({})
   const [variationItems, setVariationItems] = useState({
      [getUID()]: {
         ...STRUCTURE_VARIATION_ITEM,
      },
   })
   const [variationType, setVariationType] = useState(
      ConfiguratorEquipmentItemSelectionType.Single
   )
   const [variations, setVariations] = useState(VARIATIONS.No)

   const [addNote, setAddNote] = useState(ADD_NOTE.No)
   const [status, setStatus] = useState(true)
   const imagesRef = useRef(null)
   const { trailerId, categoryId } = useParams()
   const [isLoading, setIsLoading] = useState(false)
   const [previousImages, setPreviousImages] = useState([])
   const [isFetching, setIsFetching] = useState(false)

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      if (values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes) {
         for (const element in variationItems) {
            if (
               variationItems[element].variationItems.find(
                  item => item.quantity <= 0
               )
            ) {
               return toast.error(
                  "All variation item must have quantity above 0"
               )
            }
            if (
               !variationItems[element].variationItems.length ||
               !variationItems[element].variationImage.newImageId
            ) {
               return toast.error("Fill variations data properly")
            }
         }

         if (
            !Object.values(variationItems).find(
               item => item.defaultItem.checked
            )
         ) {
            return toast.error("Must select one default variation")
         }
      } else if (values[FIELDS_NAME.VARIATION] == VARIATIONS.No) {
         if (!values[FIELDS_NAME.DEFAULT_ITEM]?.id) {
            return toast.error("Select default inventory item")
         }
      }

      var bodyData = {
         ...(!id && {
            configuratorEquipmentSubTypeId: Number(categoryId),
         }),
         ...(values[FIELDS_NAME.VARIATION] == VARIATIONS.No && {
            itemsId: values[FIELDS_NAME.DEFAULT_ITEM]?.id,
         }),

         ...(values[FIELDS_NAME.VARIATION] == VARIATIONS.No && {
            itemQuantity: values[FIELDS_NAME.QUANTITY],
         }),

         name: values[FIELDS_NAME.NAME],
         description: values[FIELDS_NAME.DESCRIPTION],
         status: values[FIELDS_NAME.STATUS],
         price:
            values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes
               ? "0"
               : values[FIELDS_NAME.PRICE].toString(),
         addNote: Boolean(values[FIELDS_NAME.ADD_NOTE] == ADD_NOTE.Yes),
         variationFlag: Boolean(
            values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes
         ),
         selectionType: values[FIELDS_NAME.VARIATION_TYPE],
      }

      if (values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes) {
         bodyData = {
            ...bodyData,
            subTypePropertyVariations: (function () {
               const arr = []

               for (const key in variationItems) {
                  arr.push({
                     isDefault: variationItems[key]?.defaultItem.checked,
                     name: variationItems[key]?.variationName.value,
                     images: [
                        {
                           mediaId:
                              variationItems[key]?.variationImage.newImageId,
                        },
                     ],
                     price: (variationItems[
                        key
                     ]?.variationPrice.value).toString(),

                     variationsItems: variationItems[key]?.variationItems?.map(
                        item => ({
                           itemsId: item.id,
                           itemQuantity: item.quantity || 0,
                        })
                     ),
                  })
               }
               return arr
            })(),
         }
      } else if (values[FIELDS_NAME.VARIATION] == VARIATIONS.No) {
         bodyData = {
            ...bodyData,
            subTypePropertyVariations: [],
         }
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const uploadedMediaIDs = async (images, uId) => {
      if (images) {
         try {
            let res = await handleUploadImages(images, uId)
            return res?.length && res.map(item => item.id)
         } finally {
         }
      }
   }

   const handleUpdate = async data => {
      let arr = []

      const payload = {
         ...data,
      }

      let userUploadedImages = imagesRef.current.getAllImages()

      Object.values(variationImagesRef.current).forEach(
         item => item?.getAllImages && arr.push(...item?.getAllImages())
      )

      if (!userUploadedImages.length && !previousImages.length) {
         return toast.error("Upload item images !")
      }

      try {
         setIsLoading(true)

         if (userUploadedImages.length) {
            uploadedMediaIDs(userUploadedImages).then(async res => {
               const apiFormatForImages = res.map(imageId => ({
                  mediaId: imageId,
               }))
               payload.images = [
                  ...(apiFormatForImages?.length && apiFormatForImages),
                  ...(previousImages?.length
                     ? previousImages.map(image => ({
                          mediaId: image.media.id,
                       }))
                     : []),
               ]

               await handleUpdateExecution(id, payload)
               handleModalClose()
               onSuccess()
            })
         } else {
            payload.images = [
               ...(previousImages?.length &&
                  previousImages.map(image => ({
                     mediaId: image.mediaId,
                  }))),
            ]

            await handleUpdateExecution(id, payload)
            handleModalClose()
            onSuccess()
         }
      } finally {
         setIsLoading(false)
      }
   }
   const handleAdd = async data => {
      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length) {
         toast.error("Upload item images !")
         return
      }

      try {
         setIsLoading(true)
         // let variationIds = await uploadedMediaIDs(arr)
         let ids = await uploadedMediaIDs(userUploadedImages)
         let apiFormatForImages = ids.map(imageId => ({
            mediaId: imageId,
         }))
         data.images = [...(apiFormatForImages.length && apiFormatForImages)]

         let res = await AddEquipmentCategoryItems(data)
         toast.success("Item has been added successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetEquipmentCategoryItemDetails(id)

         formik.setValues({
            [FIELDS_NAME.DEFAULT_ITEM]: res.item,
            [FIELDS_NAME.QUANTITY]: res?.itemQuantity,
            [FIELDS_NAME.NAME]: res?.name,
            [FIELDS_NAME.DESCRIPTION]: res?.description,
            [FIELDS_NAME.STATUS]: res.status,
            [FIELDS_NAME.PRICE]: parseFloat(res.price).toFixed(2),
            [FIELDS_NAME.VARIATION]: res?.variationFlag
               ? VARIATIONS.Yes
               : VARIATIONS.No,
            [FIELDS_NAME.VARIATION_TYPE]: res.selectionType,
            [FIELDS_NAME.ADD_NOTE]: res.addNote ? ADD_NOTE.Yes : ADD_NOTE.No,
         })
         setStatus(handleStatusConversion(res.status))
         setAddNote(res.addNote ? ADD_NOTE.Yes : ADD_NOTE.No)
         setVariationType(res.selectionType)
         setVariations(res.variationFlag ? VARIATIONS.Yes : VARIATIONS.No)
         setPreviousImages(res?.images)
         if (res?.subTypePropertyVariations?.length) {
            let obj = {}

            res.subTypePropertyVariations.forEach((item, indx) => {
               // let id = getUID()

               obj = {
                  ...obj,

                  [item.id]: {
                     localId: "",
                     dbId: item.id,
                     defaultItem: {
                        name: "",
                        checked: item.isDefault,
                     },
                     variationName: {
                        name: "",
                        value: item.name,
                        errorMsg: "",
                     },

                     variationPrice: {
                        name: "",
                        value: parseFloat(item.price)?.toFixed(2),
                        errorMsg: "",
                     },

                     variationImage: {
                        previousImages: item.images,
                        newImageId: item.images[0].mediaId,
                     },

                     // variationItems: item.variationsItems.map(
                     //    item => item.item
                     // ),

                     variationItems: item.variationsItems.map(
                        variationItem => ({
                           ...variationItem.item,
                           quantity: variationItem.itemQuantity,
                        })
                     ),
                  },
               }
            })
            setVariationItems(obj)
         }
      } finally {
         setIsFetching(false)
      }
   }

   const handleUploadImages = async images => {
      let apiCallIns
      let uploadedIds = []

      apiCallIns = images.map(item => {
         return handleMediaUpload(item)
      })

      try {
         setIsLoading(true)
         uploadedIds = (await Promise.allSettled(apiCallIns))
            .filter(item => item.status === "fulfilled")
            .map(id => id.value)
      } finally {
         setIsLoading(false)
      }

      return uploadedIds
   }

   const handleMediaUpload = async file => {
      return initializeMediaUtil(file).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials, handleImageUploadProgress)
         return await finalizeMediaUtil(credentials?.mediaId)
      })
   }

   const handleImageUploadProgress = progress => {}

   const handleModalClose = () => {
      setStatus(true)
      formik.resetForm()
      setPreviousImages([])
      setAddNote(ADD_NOTE.No)
      setStatus(true)
      setVariationType(ConfiguratorEquipmentItemSelectionType.Single)
      setVariations(VARIATIONS.No)
      setVariationItems({
         [getUID()]: {
            ...STRUCTURE_VARIATION_ITEM,
         },
      })

      onToggleModal()
   }
   const handleSetStatus = evt => {
      let { name } = evt.target
      let alteredStatus = !status
      setStatus(alteredStatus)
      formik.setFieldValue(
         name,
         alteredStatus
            ? EquipmentCategoryItemsStatus.Active
            : EquipmentCategoryItemsStatus.Inactive
      )
   }

   const handleSetVariations = evt => {
      let { name, value } = evt.target

      setVariationItems({
         [getUID()]: {
            ...STRUCTURE_VARIATION_ITEM,
         },
      })
      setVariations(value)
      formik.setFieldValue(name, value)
   }

   const handleSetVariationType = evt => {
      let { name, value } = evt.target
      setVariationType(value)
      formik.setFieldValue(name, value)
   }
   const handleSetAddNote = evt => {
      let { name, value } = evt.target
      setAddNote(value)
      formik.setFieldValue(name, value)
   }

   const handleAddItemInVariations = (uniqueId, itemObj) => {
      const id = getUID()

      setVariationItems(prev => ({
         ...prev,
         [id]: {
            ...STRUCTURE_VARIATION_ITEM,
         },
      }))
   }

   const handleRemoveVariation = id => {
      let cloneOfCurrentList = { ...variationItems }
      delete cloneOfCurrentList[id]
      setVariationItems(cloneOfCurrentList)
   }

   const handleRemoveVariationItems = (id, removeItemId) => {
      let cloneOfCurrentList = { ...variationItems }
      // if (cloneOfCurrentList[id].variationItems?.length == 1) return
      let mutatedData = cloneOfCurrentList[id].variationItems.filter(
         item => item.id != removeItemId
      )
      cloneOfCurrentList[id].variationItems = mutatedData
      setVariationItems(cloneOfCurrentList)
   }

   const handleSetVariationName = (evt, id) => {
      setVariationItems(prev => ({
         ...prev,
         [id]: {
            ...prev[id],
            variationName: {
               ...prev[id]["variationName"],
               value: evt.target.value,
            },
         },
      }))
   }

   const handleSetVariationPrice = (evt, id) => {
      setVariationItems(prev => ({
         ...prev,
         [id]: {
            ...prev[id],
            variationPrice: {
               ...prev[id]["variationPrice"],
               value: evt.target.value,
            },
         },
      }))
   }

   const {
      setSelectedOption: setSelectedItem,
      selectEntity: itemsOptions,
      setSelectEntity: setItemsOptions,
      handleSearchOnOptions: handleSearchItems,
      selectedOption: selectedItem,
      handleSelectAOption: handleSelectAItem,
   } = useAsyncSelect({
      apiFunc: GetItemsListing,
      labelKey: "itemName",
      valueKey: "id",
      params: { limit: 100 },
      restrictExecutionOnRender: isDetailView,
      modifyLabel: true,
      handleLabelModification: options => {
         return options.map(item => ({
            ...item,
            label: `${item.skuNo}-${item.itemName}`,
         }))
      },
      onOptionSelect: (option, id) => {
         if (
            variations == VARIATIONS.Yes &&
            variationItems[id].variationItems.find(item => item.id == option.id)
         )
            return

         // setVariationItems(prev => ({
         //    ...prev,
         //    [id]: {
         //       ...prev[id],
         //       variationItems: [...prev[id].variationItems, { ...option }],
         //    },
         // }))

         if (variations == VARIATIONS.No) {
            formik.setFieldValue(FIELDS_NAME.DEFAULT_ITEM, option)
            formik.setFieldValue(FIELDS_NAME.QUANTITY, 0)
         } else {
            setVariationItems(prev => ({
               ...prev,
               [id]: {
                  ...prev[id],
                  variationItems: [
                     ...prev[id].variationItems,
                     { ...option, quantity: 0 },
                  ],
               },
            }))
         }
      },
   })

   const handleSetItemQuantity = (variationId, itemId, quantity) => {
      setVariationItems(prev => ({
         ...prev,
         [variationId]: {
            ...prev[variationId],
            variationItems: prev[variationId].variationItems.map(item =>
               item.id === itemId
                  ? { ...item, quantity: Number(quantity) }
                  : item
            ),
         },
      }))
   }

   const handleSetDefaultChange = id => {
      function updateCheckedState(obj, selectedKey) {
         return Object.keys(obj).reduce((acc, key) => {
            acc[key] = {
               ...obj[key],
               defaultItem: {
                  ...obj[key].defaultItem,
                  checked:
                     key === selectedKey
                        ? !variationItems[id].defaultItem.checked
                        : false,
               },
            }
            return acc
         }, {})
      }

      let mutated = updateCheckedState(variationItems, id)
      setVariationItems({ ...mutated })
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         sizeClasses="10"
         backdrop="static"
         modalTitle={
            isDetailView
               ? TITLES.MODAL_DETAILS_TITLE
               : id
               ? TITLES.MODAL_UPDATE_TITLE
               : TITLES.MODAL_ADD_TITLE
         }
         scrollable
      >
         {isFetching ? (
            <FormSkeleton />
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Form onSubmit={formik.handleSubmit}>
                        {/* 
                            ------------------
                            Item Name
                            ------------------
                            */}
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Item
                           </label>
                           <div className="col-md-9">
                              {!isDetailView && (
                                 <TextInput
                                    required
                                    size="md"
                                    type="text"
                                    placeholder="Enter item name"
                                    name={FIELDS_NAME.NAME}
                                    {...formik.getFieldProps(FIELDS_NAME.NAME)}
                                 />
                              )}
                              {isDetailView && (
                                 <p
                                    style={{
                                       wordWrap: "break-word",
                                       width: "100%",
                                       fontSize: "12px",
                                       marginLeft: "6px",
                                    }}
                                 >
                                    {formik.values[FIELDS_NAME.NAME]?.trim()}
                                 </p>
                              )}
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.NAME}
                              />
                           </div>
                        </Row>

                        {/* 
                            ------------------
                            Default Item
                            ------------------
                            */}
                        {variations == VARIATIONS.No && (
                           <Row className="mb-3 align-items-center">
                              <label className=" col-md-3 col-form-label clr-theme-primary">
                                 Add Inventory
                              </label>
                              <div className="col-md-9">
                                 <DefaultItemModal
                                    variations={variations}
                                    formik={formik}
                                    handleSearchItems={handleSearchItems}
                                    handleSelectAItem={handleSelectAItem}
                                    isDetailView={isDetailView}
                                    itemsOptions={itemsOptions}
                                    setItemsOptions={setItemsOptions}
                                 />
                              </div>
                           </Row>
                        )}

                        {/* 
                            ------------------
                            Item Description
                            ------------------
                            */}
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Item Description
                           </label>
                           <div className="col-md-9">
                              {!isDetailView && (
                                 // <TextInput
                                 //    size="md"
                                 //    type="textarea"
                                 //    placeholder="Enter item description"
                                 //    name={FIELDS_NAME.DESCRIPTION}
                                 //    {...formik.getFieldProps(
                                 //       FIELDS_NAME.DESCRIPTION
                                 //    )}
                                 // />

                                 <ReactQuill
                                    className="quill-description-write"
                                    theme="snow"
                                    placeholder="Enter item description"
                                    value={
                                       formik.values[FIELDS_NAME.DESCRIPTION]
                                    }
                                    onChange={value =>
                                       formik.setFieldValue(
                                          FIELDS_NAME.DESCRIPTION,
                                          value
                                       )
                                    }
                                 />
                              )}
                              {isDetailView && (
                                 <div
                                    className="quill-description-readonly"
                                    dangerouslySetInnerHTML={{
                                       __html:
                                          formik.values[
                                             FIELDS_NAME.DESCRIPTION
                                          ],
                                    }}
                                 />

                                 // <p
                                 //    style={{
                                 //       wordWrap: "break-word",
                                 //       width: "100%",
                                 //       fontSize: "12px",
                                 //       marginLeft: "6px",
                                 //    }}
                                 // >
                                 //    {formik.values[
                                 //       FIELDS_NAME.DESCRIPTION
                                 //    ].trim()}
                                 // </p>
                              )}

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.DESCRIPTION}
                              />
                           </div>
                        </Row>
                        {/* 
                            ------------------
                            Item Price
                            ------------------
                            */}
                        {variations == VARIATIONS.No && (
                           <Row className="mt-4 mb-3 align-items-center">
                              <label className=" col-md-3 col-form-label clr-theme-primary">
                                 Price ($)
                              </label>
                              <div className="col-md-9">
                                 <TextInput
                                    type="number"
                                    min={0}
                                    max="99999999.99"
                                    step="0.01"
                                    inputClass={
                                       isDetailView && "input-readonly"
                                    }
                                    placeholder="Enter item price"
                                    name={FIELDS_NAME.PRICE}
                                    {...formik.getFieldProps(FIELDS_NAME.PRICE)}
                                 />

                                 <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={FIELDS_NAME.PRICE}
                                 />
                              </div>
                           </Row>
                        )}

                        {/* 
                            -------------------------------
                            Add Quantity
                            -------------------------------
                            */}
                        {/* <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Quantity
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 type="number"
                                 // min={0}
                                 step="0.01"
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter item quantity"
                                 name={FIELDS_NAME.QUANTITY}
                                 {...formik.getFieldProps(FIELDS_NAME.QUANTITY)}
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.QUANTITY}
                              />
                           </div>
                        </Row> */}

                        {/* 
                            -------------------------------
                            Add Note
                            -------------------------------
                            */}
                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label">
                              Add Note
                           </label>
                           <div className="col-md-9">
                              <div className="d-flex gap-2">
                                 <label className="d-flex align-items-center gap-1">
                                    <TextInput
                                       disabled={isDetailView}
                                       type="radio"
                                       name={FIELDS_NAME.ADD_NOTE}
                                       value={ADD_NOTE.Yes}
                                       checked={addNote == ADD_NOTE.Yes}
                                       onClick={handleSetAddNote}
                                    />
                                    <span>Yes</span>
                                 </label>

                                 <label className="d-flex align-items-center gap-1">
                                    <TextInput
                                       disabled={isDetailView}
                                       type="radio"
                                       name={FIELDS_NAME.ADD_NOTE}
                                       value={ADD_NOTE.No}
                                       checked={addNote == ADD_NOTE.No}
                                       onClick={handleSetAddNote}
                                    />
                                    <span>No</span>
                                 </label>
                              </div>
                           </div>
                        </Row>
                        {/* 
                            -------------------------------
                             Status
                            -------------------------------
                            */}
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Status
                           </label>
                           <div className="col-md-9">
                              <ActiveInactiveSwitch
                                 isChecked={status}
                                 isDisable={isDetailView}
                                 name={FIELDS_NAME.STATUS}
                                 onChange={handleSetStatus}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.STATUS}
                              />
                           </div>
                        </Row>
                        {/* 
                            ------------------
                            Upload Images
                            ------------------
                            */}
                        <Row className="mt-4 mb-3 align-items-start">
                           <label className=" col-md-3  clr-theme-primary">
                              Upload Images
                           </label>
                           <div className="col-md-9">
                              <ImageGallery
                                 ref={imagesRef}
                                 previousImages={previousImages}
                                 removePreviousImages={setPreviousImages}
                                 previewOnly={isDetailView}
                                 isDetailView={isDetailView}
                              />
                           </div>
                        </Row>
                        {/* 
                            ----------------------
                            Variations Selection
                            ----------------------
                            */}
                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label">
                              Variations
                           </label>
                           <div className="col-md-9">
                              <div className="d-flex gap-2">
                                 <label className="d-flex align-items-center gap-1">
                                    <TextInput
                                       disabled={
                                          isDetailView ||
                                          !formik.values[FIELDS_NAME.NAME]
                                       }
                                       type="radio"
                                       name={FIELDS_NAME.VARIATION}
                                       value={VARIATIONS.Yes}
                                       checked={variations == VARIATIONS.Yes}
                                       onClick={handleSetVariations}
                                    />
                                    <span>Yes</span>
                                 </label>

                                 <label className="d-flex align-items-center gap-1">
                                    <TextInput
                                       disabled={
                                          isDetailView ||
                                          !formik.values[FIELDS_NAME.NAME]
                                       }
                                       type="radio"
                                       name={FIELDS_NAME.VARIATION}
                                       value={VARIATIONS.No}
                                       checked={variations == VARIATIONS.No}
                                       onClick={handleSetVariations}
                                    />
                                    <span>No</span>
                                 </label>
                              </div>
                           </div>
                        </Row>
                        {/* 
                            ---------------------------------
                            Single or Multiple Variation Type
                            ---------------------------------
                            */}
                        {variations == VARIATIONS.Yes && (
                           <Row className="mt-4 mb-3 align-items-center">
                              <label className=" col-md-3 col-form-label"></label>
                              <div className="col-md-9">
                                 <div className="d-flex gap-2 ">
                                    <label className="d-flex align-items-center gap-1">
                                       <TextInput
                                          disabled={isDetailView}
                                          type="radio"
                                          name={FIELDS_NAME.VARIATION_TYPE}
                                          value={
                                             ConfiguratorEquipmentItemSelectionType.Single
                                          }
                                          checked={
                                             variationType ==
                                             ConfiguratorEquipmentItemSelectionType.Single
                                          }
                                          onClick={handleSetVariationType}
                                       />
                                       <span>Single Select</span>
                                    </label>

                                    <label className="d-flex align-items-center gap-1">
                                       <TextInput
                                          disabled={isDetailView}
                                          type="radio"
                                          name={FIELDS_NAME.VARIATION_TYPE}
                                          value={
                                             ConfiguratorEquipmentItemSelectionType.Multiple
                                          }
                                          checked={
                                             variationType ==
                                             ConfiguratorEquipmentItemSelectionType.Multiple
                                          }
                                          onClick={handleSetVariationType}
                                       />
                                       <span>Multiple Select</span>
                                    </label>
                                 </div>
                              </div>
                           </Row>
                        )}
                        {/* 
                            -------------------------------
                            Added Item Field
                            -------------------------------
                            */}
                        {variations == VARIATIONS.Yes && (
                           <Row className="mb-3" style={{ zIndex: 999999999 }}>
                              <label className=" col-md-3 col-form-label clr-theme-primary"></label>
                              <div className="col-md-9">
                                 {/* 
                            -------------------------------
                            Variation Box
                            -------------------------------
                            */}
                                 {Object.keys(variationItems)?.map(
                                    (uniqueKey, indx) => {
                                       return (
                                          <>
                                             <div
                                                style={
                                                   !formik.values[
                                                      FIELDS_NAME.NAME
                                                   ]
                                                      ? {
                                                           opacity: "0.5",
                                                           pointerEvents:
                                                              "none",
                                                        }
                                                      : {}
                                                }
                                                className="d-flex justify-content-start align-items-center gap-2  pb-2"
                                                key={indx}
                                             >
                                                {/* Cross Icon */}
                                                {!isDetailView && (
                                                   <img
                                                      onClick={() =>
                                                         handleRemoveVariation(
                                                            uniqueKey
                                                         )
                                                      }
                                                      style={{
                                                         width: "20px",
                                                         height: "20px",
                                                      }}
                                                      className="pointer"
                                                      src={crossIcon}
                                                   />
                                                )}
                                                {/* Default Item */}

                                                <div
                                                   className={`d-flex flex-column align-items-center justify-content-center pb-3 mx-2 ${
                                                      isDetailView && "mt-2"
                                                   }`}
                                                >
                                                   <small className="fw-bold">
                                                      Default
                                                   </small>
                                                   <div className="my-1">
                                                      <TextInput
                                                         disabled={isDetailView}
                                                         inputClass={
                                                            !isDetailView
                                                               ? "pointer"
                                                               : ""
                                                         }
                                                         type="checkbox"
                                                         checked={
                                                            variationItems[
                                                               uniqueKey
                                                            ]["defaultItem"]
                                                               .checked
                                                         }
                                                         onClick={() =>
                                                            handleSetDefaultChange(
                                                               uniqueKey
                                                            )
                                                         }
                                                      />
                                                   </div>
                                                </div>
                                                {/* Variation Name */}
                                                <div className="w-25 pb-3">
                                                   <small className="fw-bold">
                                                      Variation Name
                                                   </small>
                                                   <TextInput
                                                      required
                                                      type="text"
                                                      inputClass={`${
                                                         isDetailView &&
                                                         "input-readonly"
                                                      } w-100`}
                                                      placeholder={
                                                         "Enter variation name"
                                                      }
                                                      value={
                                                         variationItems[
                                                            uniqueKey
                                                         ]["variationName"]
                                                            ?.value
                                                      }
                                                      onChange={e =>
                                                         handleSetVariationName(
                                                            e,
                                                            uniqueKey
                                                         )
                                                      }
                                                   />
                                                </div>
                                                {/* Variation Price */}
                                                <div className="w-25 pb-3">
                                                   <small className="fw-bold">
                                                      Variation Price ($)
                                                   </small>

                                                   <TextInput
                                                      type="number"
                                                      min={0}
                                                      step={0.01}
                                                      max="99999999.99"
                                                      required
                                                      inputClass={`${
                                                         isDetailView &&
                                                         "input-readonly"
                                                      } w-100`}
                                                      placeholder={
                                                         "Enter variation price"
                                                      }
                                                      value={
                                                         variationItems[
                                                            uniqueKey
                                                         ]["variationPrice"]
                                                            ?.value
                                                      }
                                                      onChange={e =>
                                                         handleSetVariationPrice(
                                                            e,
                                                            uniqueKey
                                                         )
                                                      }
                                                   />
                                                </div>
                                                {/* Variation Image */}
                                                <div className="w-25 ">
                                                   <ImageGallery
                                                      maxFileSize={
                                                         2 * 1024 * 1024
                                                      }
                                                      id={uniqueKey}
                                                      isUploadOnServer
                                                      MAX_IMAGES={1}
                                                      isMulti={false}
                                                      hasDataStructure
                                                      previousImages={
                                                         variationItems[
                                                            uniqueKey
                                                         ].variationImage
                                                            .previousImages
                                                      }
                                                      cbRemoveImage={newArr => {
                                                         setVariationItems(
                                                            prev => ({
                                                               ...prev,
                                                               [uniqueKey]: {
                                                                  ...prev[
                                                                     uniqueKey
                                                                  ],
                                                                  variationImage:
                                                                     {
                                                                        previousImages:
                                                                           newArr,
                                                                        newImageId:
                                                                           "",
                                                                     },
                                                               },
                                                            })
                                                         )
                                                      }}
                                                      previewOnly={isDetailView}
                                                      isDetailView={
                                                         isDetailView
                                                      }
                                                      handleSetMediaId={(
                                                         uid,
                                                         mediaId
                                                      ) => {
                                                         return setVariationItems(
                                                            prev => ({
                                                               ...prev,
                                                               [uid]: {
                                                                  ...prev[uid],
                                                                  variationImage:
                                                                     {
                                                                        ...prev[
                                                                           uid
                                                                        ]
                                                                           ?.variationImage,
                                                                        newImageId:
                                                                           mediaId,
                                                                     },
                                                               },
                                                            })
                                                         )
                                                      }}
                                                   />
                                                </div>
                                             </div>
                                             <VariationItemsModal
                                                formik={formik}
                                                handleRemoveVariationItems={
                                                   handleRemoveVariationItems
                                                }
                                                handleSearchItems={
                                                   handleSearchItems
                                                }
                                                handleSelectAItem={
                                                   handleSelectAItem
                                                }
                                                handleSetItemQuantity={
                                                   handleSetItemQuantity
                                                }
                                                isDetailView={isDetailView}
                                                itemsOptions={itemsOptions}
                                                setItemsOptions={
                                                   setItemsOptions
                                                }
                                                uniqueKey={uniqueKey}
                                                variationItems={variationItems}
                                                key={indx}
                                             />
                                             <hr />
                                          </>
                                       )
                                    }
                                 )}

                                 {/* Add Item Button */}
                                 {!isDetailView &&
                                    formik.values[FIELDS_NAME.VARIATION] ==
                                       VARIATIONS.Yes && (
                                       <div
                                          className="add-more-container mt-4 pointer"
                                          onClick={handleAddItemInVariations}
                                       >
                                          <div className="d-flex gap-1 justify-content-start">
                                             <img
                                                style={{
                                                   width: "20px",
                                                   height: "20px",
                                                }}
                                                className="cursor"
                                                src={addIcon}
                                             />
                                             <span
                                                style={{
                                                   color: "#1374D5",
                                                }}
                                             >
                                                Add Variation
                                             </span>
                                          </div>
                                       </div>
                                    )}
                              </div>
                           </Row>
                        )}
                        <div className="d-flex justify-content-center mt-3 mt-2">
                           <Button
                              isLoading={isLoading}
                              type={isDetailView ? "button" : "submit"}
                              title={
                                 isDetailView
                                    ? TITLES.DETAILS_BTN_TEXT
                                    : id
                                    ? TITLES.UPDATE_BTN_TEXT
                                    : TITLES.ADD_BTN_TEXT
                              }
                              className="header-button"
                              onClick={isDetailView && handleModalClose}
                           />
                        </div>
                     </Form>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default React.memo(ManageEquipmentCategoryItem)

import React from "react"
import "./Style.scss"

const ActiveInactiveSwitch = ({
   id,
   isDisable = false,
   onChange,
   isChecked = null,
   value,
   name,
   activeText = "Active",
   inActiveText = "Inactive",
}) => {
   return (
      <div
         className="status-switch-container"
         style={{ cursor: isDisable ? "default" : "pointer" }}
      >
         <span>{inActiveText}</span>
         <label className="status-switch">
            <input
               id={id}
               disabled={isDisable}
               type="checkbox"
               name={name}
               onClick={onChange}
               checked={isChecked}
               value={isChecked}
            />
            <span
               className="status-slider round"
               style={{ cursor: isDisable ? "default" : "pointer" }}
            ></span>
         </label>
         <span>{activeText}</span>
      </div>
   )
}

export default React.memo(ActiveInactiveSwitch)

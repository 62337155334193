import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
   AccordionBody,
   AccordionHeader,
   AccordionItem,
   UncontrolledAccordion,
} from "reactstrap"
import "../style.scss"
import { GetPackagesListing } from "api/api.service"
import { Loader } from "components/Loader"
import { ConfiguratorStatus } from "constants"
import { DataTable } from "components/DataTable"
import { ENHANCEMENT_TYPES } from "pages/Configurator/Packages/FormConfig"

const Packages = () => {
   let { trailerId } = useParams()
   const [packageData, setPackageData] = useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      getPackages()
   }, [trailerId])

   const getPackages = async () => {
      try {
         setLoading(true)
         let res = await GetPackagesListing({ trailerId: trailerId, limit: -1 })
         setPackageData(res)
      } catch (error) {
      } finally {
         setLoading(false)
      }
   }

   if (loading) {
      return <Loader />
   }

   if (packageData.total < 1) {
      return (
         <div className="d-flex justify-content-center align-items-center">
            <h2 className="p-4">No Data</h2>
         </div>
      )
   }

   return (
      <UncontrolledAccordion defaultOpen={0} className="my-4">
         {packageData?.data?.map((pkgObj, ind) => (
            <AccordionItem key={ind}>
               <AccordionHeader targetId={ind}>
                  <div className="accordion-title-container d-flex gap-4">
                     <h4 className="accordion-title-blue  p-0 m-0">
                        {pkgObj.packageName}
                     </h4>
                     <h4 className="accordion-title-black p-0 m-0">
                        ${pkgObj.price}
                     </h4>
                     <div
                        className={`configurator-status ${ConfiguratorStatus.getLabelClass(
                           pkgObj.status
                        )}`}
                     >
                        <span>
                           {ConfiguratorStatus.getDisplayTextKey(pkgObj.status)}
                        </span>
                     </div>
                  </div>
               </AccordionHeader>
               <AccordionBody accordionId={ind}>
                  <UncontrolledAccordion defaultOpen={0} className="my-4">
                     {pkgObj?.items?.map((pkgObjItem, ind) => (
                        <AccordionItem key={ind}>
                           <AccordionHeader targetId={ind}>
                              <div className="accordion-title-container d-flex gap-4">
                                 <h4 className="accordion-title-blue  p-0 m-0">
                                    {pkgObjItem.item.itemName}
                                 </h4>
                                 <h4 className="accordion-title-black p-0 m-0">
                                    ${pkgObjItem.totalPrice}
                                 </h4>

                                 <h4 className="accordion-title-black p-0 m-0">
                                    Qty: {pkgObjItem.quantity}
                                 </h4>
                                 <div
                                    className={`configurator-status ${ConfiguratorStatus.getLabelClass(
                                       pkgObjItem.status
                                    )}`}
                                 >
                                    <span>
                                       {ConfiguratorStatus.getDisplayTextKey(
                                          pkgObjItem.status
                                       )}
                                    </span>
                                 </div>
                                 <div className="accordion-header-right">
                                    {pkgObjItem.enhancementType ==
                                    ENHANCEMENT_TYPES.Replacement
                                       ? "Removal"
                                       : pkgObjItem.enhancementType}
                                 </div>
                              </div>
                           </AccordionHeader>
                           <AccordionBody accordionId={ind}>
                              <h4 className="accordion-title-black">
                                 Removal Items
                              </h4>
                              <div>
                                 <DataTable
                                    data={pkgObjItem.replacementItems}
                                    tableClasses="upload-inv-file-listing"
                                    config={[
                                       {
                                          title: "Item",
                                          render: data =>
                                             data?.item?.itemName || "-",
                                       },
                                       {
                                          title: "New Qty",
                                          render: data => data?.quantity || "-",
                                       },
                                       {
                                          title: "Old Qty",
                                          render: data =>
                                             data?.oldQuantity || "-",
                                       },
                                    ]}
                                 />
                              </div>
                           </AccordionBody>
                        </AccordionItem>
                     ))}
                  </UncontrolledAccordion>
               </AccordionBody>
            </AccordionItem>
         ))}
      </UncontrolledAccordion>
   )
}

export default Packages

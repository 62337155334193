import React from "react"

const NavTabsRoutingBasedButtons = ({
   data = [],
   classes = "",
   isActive = undefined,
   size = "lg",
}) => {
   return (
      <>
         {data.length &&
            data.map((item, index) => {
               return (
                  <div
                     key={index}
                     className={` mx-xl-2 d-flex justify-content-center align-items-center ${
                        isActive !== undefined &&
                        (isActive[item.identifier] === true
                           ? "bg-navitems-primary"
                           : "bg-navitems-secondary")
                     }`}
                     onClick={evt => item.onClick(true)}
                  >
                     <img
                        src={
                           isActive !== undefined &&
                           (isActive[item.identifier] === true
                              ? item.activeIcon
                              : item.icon)
                        }
                     />
                     <a
                        size={size}
                        className={`${classes} ${item.classesButton} `}
                     >
                        {item?.title}
                     </a>
                  </div>
               )
            })}
      </>
   )
}

export default NavTabsRoutingBasedButtons

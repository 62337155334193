import React, { useState, useEffect } from "react"
import { Col, Row } from "reactstrap"
import toast from "react-hot-toast"

import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import financeediticon from "../../../../assets/images/icons/edit-icon.svg"
import { Modal } from "components/Modal"
import DeleteModal from "../modals/DeleteModal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
   WarehouseListing,
   AddWarehouse,
   UpdateWarehouse,
   DeleteWarehouse,
} from "api/api.service"
import { WarehouseHeader } from "../../Common/Header"
import { hasData, truncateString } from "utils/commonUtils"
import AddEditWarehouse from "../modals/AddEditWarehouse"

const COLUMNS = {
   S_NO: "S.No.",
   WAREHOUSE_NAME: "Warehouse(s)",
   ASSIGN_TO: "Assigned To",
   ADDRESS: "Address",
   LOCATION: "Location",
   ACTIONS: "Actions",
}

const MODAL_ACTIONS = {
   ADD: "1",
   EDIT: "2",
   DELETE: "3",
}

const dataKey = "data"
const totalKey = "total"
const CHARACTER_LIMIT = 20
let count = 1

const getAllUsersNameOfWarehouse = users => {
   let str = users
      .map(
         item =>
            item?.["user"]?.["firstName"] + " " + item?.["user"]?.["lastName"]
      )
      .join(", ")
   return str
}

const Warehouse_Location_Listing = ({}) => {
   const [actionStates, setActionStates] = useState({
      isAddWarehouse: null,
      isEditWarehouse: null,
      isDeleteWarehouse: null,
   })
   const [currentActionKey, setCurrentActionKey] = useState(null)
   const [selectedRowData, setSelectedRowData] = useState(null)
   const [isLoading, setIsLoading] = useState(false)
   const [isDataUpdated, setIsDataUpdated] = useState(false)
   const [recordDataID, setRecordDataID] = useState(undefined)
   const [filters, setFilters] = useState(null)
   const [fetchedFirstTime, setFetchedFirstTime] = useState(false)
   const {
      data,
      isFetching,
      page,
      searchText,
      pageSize,
      request,
      handleSearchChange,
      onChangePageSize,
      handlePageClick,
      total,
   } = useLocalPaginatedRequest({
      requestFn: WarehouseListing,
      params: {
         ...(filters?.Status && { Status: filters?.Status }),
      },
      deps: [filters, isDataUpdated],
      dataKey: dataKey,
      totalKey: totalKey,
   })

   const handleEditWarehouse = data => {
      setSelectedRowData(data)
      setCurrentActionKey(MODAL_ACTIONS.EDIT)
      setActionStates(prev => ({ ...prev, isEditWarehouse: true }))
   }

   let currentAction = {
      [MODAL_ACTIONS.ADD]: {
         title: "Add Warehouse",
         btnText: "Add Warehouse",
      },
      [MODAL_ACTIONS.EDIT]: {
         title: "Edit Warehouse",
         btnText: "Edit Warehouse",
      },
      [MODAL_ACTIONS.DELETE]: {
         title: "Delete Warehouse",
         btnText: "Delete Warehouse",
         value: "Location",
      },
   }

   const handleCloseModal = () => {
      setActionStates({
         isAddWarehouse: null,
         isEditWarehouse: null,
         isDeleteWarehouse: null,
      })
      setCurrentActionKey(null)
   }

   const handleAddWarehouse = () => {
      setSelectedRowData(null)
      setCurrentActionKey(MODAL_ACTIONS.ADD)
      setActionStates(prev => ({ ...prev, isAddWarehouse: true }))
   }

   const handleSubmit = values => {
      let { name, address, location, user } = values

      let userIdArr = [...new Set(user?.map(item => item.value))]

      let formBody = {
         warehouseName: name,
         ...(address && { address: address }),
         ...(location && { location: location }),
         userId: userIdArr,
         status: "ACTIVE",
      }
      handleSumbitForm(formBody)
   }

   const handleSumbitForm = async formBody => {
      try {
         setIsLoading(true)
         let response =
            currentActionKey == "2"
               ? await UpdateWarehouse(selectedRowData.id, formBody, false)
               : await AddWarehouse(formBody, false)
         updateAPISuccess()
         toast.success("Success !")
         request()
         handleCloseModal()
      } catch (err) {
         // toast.error(err.message)
      } finally {
         setIsLoading(false)
      }
   }

   const deleteRecordHandler = async () => {
      try {
         setIsLoading(true)
         let response = await DeleteWarehouse(recordDataID)
         updateAPISuccess()
         toast.success("Warehouse has been deleted successfully!")
         request()
         handleCloseModal()
      } catch (err) {
         // toast.error(err.message)
      } finally {
         setIsLoading(false)
      }
   }
   useEffect(() => {
      if (count === 1 && !isFetching) {
         setFetchedFirstTime(true)
         count++
      }
   }, [isFetching])
   const updateAPISuccess = () => setIsDataUpdated(!isDataUpdated)
   const isShowButton = () => !isFetching && total < 1 && data?.length > 0
   return (
      <>
         <Row>
            <Col>
               <WarehouseHeader
                  btnTitle="Add New Warehouse"
                  onClick={handleAddWarehouse}
                  searchText={searchText}
                  onChangeHandler={handleSearchChange}
                  flexResponsive="location-flex-responsiveness"
                  searchResponsiveness="location-searchResponsiveness"
                  flexResponsiveInner="location-flexResponsiveInner"
                  headerBtnResponsive="ad-location-header-btn-responsive"
                  SearchBoxContainer="admin-location-search-box-container"
                  showButton={isShowButton()}
               />
            </Col>
            <Col md={12}>
               <DataTable
                  data={data}
                  loading={isFetching}
                  className="location-data-container mt-3"
                  tableClasses="ad-location-table"
                  config={[
                     {
                        title: COLUMNS.S_NO,
                        className: "black-color fm-ftwg-600  ",
                        render: data => data?.id || "-",
                     },
                     {
                        title: COLUMNS.WAREHOUSE_NAME,
                        className: "",
                        render: data => data?.warehouseName || "-",
                     },
                     {
                        title: COLUMNS.ASSIGN_TO,
                        className: "",
                        render: data =>
                           getAllUsersNameOfWarehouse(
                              data.warehouseAssignments
                           ),
                     },
                     {
                        title: COLUMNS.ADDRESS,
                        className: "",
                        render: data => {
                           if (data?.address?.length > CHARACTER_LIMIT) {
                              return (
                                 <div data-container={data?.address}>
                                    {truncateString(
                                       data?.address,
                                       CHARACTER_LIMIT
                                    )}
                                 </div>
                              )
                           } else {
                              return data?.address || "-"
                           }
                        },
                     },
                     {
                        title: COLUMNS.LOCATION,
                        className: "",
                        render: data => data?.location || "-",
                     },
                     {
                        title: COLUMNS.ACTIONS,
                        className: "",
                        render: data => (
                           <div className="d-flex justify-content-between location-icon-container">
                              <span
                                 className=" pointer "
                                 onClick={e => handleEditWarehouse(data)}
                              >
                                 <img src={financeediticon} />
                              </span>
                           </div>
                        ),
                     },
                  ]}
               />
            </Col>
         </Row>
         <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
         />
         <Modal
            isOpen={!!currentAction[currentActionKey]}
            handleModalToggling={handleCloseModal}
            bodyClassName="p-0"
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="modal-header-container"
            size="lg"
            modalTitle={currentAction[currentActionKey]?.title}
         >
            {(actionStates.isAddWarehouse || actionStates.isEditWarehouse) && (
               <AddEditWarehouse
                  btnText={currentAction[currentActionKey].btnText}
                  activeModalAction={currentActionKey}
                  data={selectedRowData}
                  onSubmitForm={handleSubmit}
                  isLoading={isLoading}
               />
            )}
            {actionStates.isDeleteWarehouse && (
               <DeleteModal
                  handleModalClose={handleCloseModal}
                  value="Warehouse"
                  handleDeleteRecord={deleteRecordHandler}
               />
            )}
         </Modal>
      </>
   )
}

export default Warehouse_Location_Listing

import React from "react"
import "./style.scss"

const ItemCard = props => {
   const { image = "", name = "", price = 0, isDefault = true } = props
   return (
      <div
         className={`item-card ${
            isDefault ? "align-items-start" : "align-items-center"
         }`}
      >
         <div className="item-card-detail">
            <img src={image} alt={name || "item"} />

            <p className="item-card-detail-name">{name}</p>
         </div>

         <div className="item-card-right">
            {isDefault ? (
               <span className="item-card-right-badge">Default</span>
            ) : null}{" "}
            <p className="item-card-right-price">${price}</p>
         </div>
      </div>
   )
}

export default ItemCard

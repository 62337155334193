import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"

import { GetWarrantyDetails } from "../../../api/api.service"
import { Button } from "../../../components/Button"
import { Modal } from "../../../components/Modal"
import { ConfiguratorPackagesStatus } from "../../../constants"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { FIELDS_NAME } from "./FormConfig.js"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"

const TITLES = {
   MODAL_DETAILS_TITLE: "Warranty Details",
   DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
   return status == ConfiguratorPackagesStatus.Active ? true : false
}

const WarrantyDetails = ({
   isOpen = false,
   onToggleModal = () => {},
   id = "",
}) => {
   const [isLoading, setIsLoading] = useState(false)
   const [status, setStatus] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const [details, setDetails] = useState({})

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
         setIsFetching(false)
      }
   }, [id])

   const handleFetchDetails = async () => {
      if (!id) return
      try {
         setIsFetching(true)
         let res = await GetWarrantyDetails(id)
         setDetails(res)
         setStatus(handleStatusConversion(res.status))
      } finally {
         setIsFetching(false)
      }
   }

   const handleModalClose = () => {
      onToggleModal()
      setStatus(false)
   }
   const handleSetStatus = evt => {
      let alteredStatus = !status
      setStatus(alteredStatus)
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container justify-content-center mb-0"
         sizeClasses="2"
         backdrop="static"
         modalTitle={TITLES.MODAL_DETAILS_TITLE}
         scrollable
      >
         {isFetching ? (
            <div>
               <FormSkeleton />
            </div>
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Warranty
                        </label>
                        <div className="col-md-9">
                           <span className="fs-14">{details?.name || "-"}</span>
                        </div>
                     </Row>

                     <Row className="mt-4 mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           No. of Years
                        </label>
                        <div className="col-md-9">
                           <span className="fs-14">{details?.year || "-"}</span>
                        </div>
                     </Row>

                     <Row className="mt-4 mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Price ($)
                        </label>
                        <div className="col-md-9">
                           <span className="fs-14">
                              {details?.price || "-"}
                           </span>
                        </div>
                     </Row>

                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Status
                        </label>
                        <div className="col-md-9">
                           <ActiveInactiveSwitch
                              isChecked={status}
                              isDisable={true}
                              name={FIELDS_NAME.STATUS}
                              onChange={handleSetStatus}
                           />
                        </div>
                     </Row>

                     <div className="d-flex justify-content-center mt-3 mt-2">
                        <Button
                           isLoading={isLoading}
                           type={"button"}
                           title={TITLES.DETAILS_BTN_TEXT}
                           className="header-button"
                           onClick={handleModalClose}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default WarrantyDetails

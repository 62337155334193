import React from "react"
import { UncontrolledTooltip } from "reactstrap"

const Dimensions = () => {
   return (
      <div className="p-2">
         <span className="dimension-text" id="DimensionsTooltip">
            View dimensions
         </span>
         <UncontrolledTooltip
            placement="bottom"
            target="DimensionsTooltip"
            trigger="hover"
         >
            <div style={{ textAlign: "left" }}>
               <p className="m-0">
                  <strong>Ideal Dimension:</strong> 640 × 227
               </p>
               <p className="m-0">
                  <strong>Acceptable Dimensions:</strong>
               </p>
               <div>5616 × 3744</div>
               <div>1920 × 1280</div>
               <div>1280 × 853</div>
               <p className="m-0">
                  <strong>Aspect Ratio:</strong> 3:2
               </p>
            </div>
         </UncontrolledTooltip>
      </div>
   )
}

export default Dimensions

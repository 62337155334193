import * as Yup from "yup"

export const FIELDS_NAME = {
   NAME: "name",
   PRICE: "price",
   YEAR: "year",
   STATUS: "status",
}

export const initialValues = {
   [FIELDS_NAME.PRICE]: "",
   [FIELDS_NAME.NAME]: "",
   [FIELDS_NAME.YEAR]: null,
   [FIELDS_NAME.STATUS]: "INACTIVE",
}

export const validationSchema = Yup.object({
   [FIELDS_NAME.NAME]: Yup.string()
      .required("Enter warranty name")
      .min(1, "Name must be at least 1 characters")
      .max(100, "Name can't be more than 100 characters"),
   [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
   [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
      .required("Enter price")
      .moreThan(0, "Price must be greater than 0")
      .test("", "Price must be between 1 and 8 numbers", item => {
         let num = item?.toString()?.length
         return num <= 8 && num >= 1
      }),
   [FIELDS_NAME.YEAR]: Yup.mixed("Enter numbers only").required("Select years"),
})

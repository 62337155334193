import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import {
   GetPackageItemDetails,
   GetDepartmentsListing,
   WarehouseInventoryListing,
   UpdatePackageItem,
   CreatePackageItem,
   GetConfiguratorBuildItems,
   GetBuildStationsListing,
   GetDepartmentInventoryListing,
   GetBuildItemsListing,
} from "../../../api/api.service"
import {
   FIELDS_NAME,
   ENHANCEMENT_TYPES,
   packageItemInitialValues,
   packageItemValidationSchema,
} from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import crossIcon from "../../../assets/images/cross-red.png"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"
import useAsyncSelect from "hooks/useAsyncSelect"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
   finalizeMediaUtil,
   initializeMediaUtil,
   uploadOnS3,
} from "utils/mediaUtils"
import MainItemModal from "./MainItemModal"
import EnhancementItemsModal from "./EnhancementItemsModal"

const TITLES = {
   MODAL_ADD_TITLE: "Add new item",
   MODAL_UPDATE_TITLE: "Update item",
   MODAL_DETAILS_TITLE: "Item details",
   ADD_BTN_TEXT: "Save",
   UPDATE_BTN_TEXT: "Update",
   DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
   return status == "ACTIVE" ? true : false
}

const checkRemoveReplacementItemEligibility = (
   enhancementType,
   replacementItems
) => {
   let MIN_LENGTH_OF_REPLACEMENT_ITEM_RECORD = 1
   return enhancementType == ENHANCEMENT_TYPES.Replacement &&
      Object.keys(replacementItems).length ==
         MIN_LENGTH_OF_REPLACEMENT_ITEM_RECORD
      ? "none"
      : "auto"
}

const handleUpdateExecution = async (id, data) => {
   let res = await UpdatePackageItem(id, data)
   toast.success("Item has been updated successfully !")
}

const ManagePackageItem = ({
   isOpen = false,
   isDetailView = false,
   isUpdating = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const imagesRef = useRef(null)
   const [replacementItems, setReplacementItems] = useState({})
   const { trailerId, packageId } = useParams()
   const [isLoading, setIsLoading] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const [previousItemId, setPreviousItemId] = useState(null)
   const [configuratorBuildId, setConfiguratorBuildId] = useState("")
   const [previousImages, setPreviousImages] = useState([])

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...packageItemInitialValues,
      },
      validationSchema: packageItemValidationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })
   // Station Involved
   const {
      setSelectedOption: setSelectedStation,
      selectEntity: stationsOptions,
      setSelectEntity: setStationsOptions,
      handleSearchOnOptions: handleSearchStations,
      selectedOption: selectedStation,
      handleSelectAOption: handleSelectAStation,
   } = useAsyncSelect({
      apiFunc: GetBuildStationsListing,
      labelKey: "departmentName",
      valueKey: "departmentId",
      params: {
         limit: -1,
         trailerId: trailerId,
      },
      restrictExecutionOnRender: isDetailView,
      onOptionSelect: option => {
         formik.setFieldValue(FIELDS_NAME.STATION_INVOLVED, option)
         formik.setFieldValue(FIELDS_NAME.ITEM, null)
         formik.setFieldValue(FIELDS_NAME.PRICE, "")
         formik.setFieldValue(FIELDS_NAME.QUANTITY, "")
         formik.setFieldValue(
            FIELDS_NAME.ENHANCEMENT_TYPE,
            ENHANCEMENT_TYPES.Addition
         )
         setReplacementItems({})
      },
   })
   // Enhancement Item
   const {
      setIsRefetch,
      setSelectedOption: setSelectedReplacementItem,
      selectEntity: replacementItemsOptions,
      setSelectEntity: setReplacementItemsOptions,
      handleSearchOnOptions: handleSearchReplacementItems,
      selectedOption: selectedReplacementItem,
      handleSelectAOption: handleSelectAReplacementItem,
   } = useAsyncSelect({
      apiFunc: GetBuildItemsListing,
      labelKey: "itemName",
      valueKey: "itemsId",
      applyOptionsFilter: true,
      keyToCheck: "itemsId",
      valueToMatch: formik.values[FIELDS_NAME.ITEM]?.itemsId,
      params: {
         limit: -1,
         configuratorTrailerId: trailerId,
         departmentId: formik.values[FIELDS_NAME.STATION_INVOLVED]?.value,
         configuratorBuildId:
            isDetailView || isUpdating
               ? configuratorBuildId
               : formik.values[FIELDS_NAME.STATION_INVOLVED]?.id,
      },
      restrictExecutionOnRender:
         isDetailView ||
         formik.values[FIELDS_NAME.ENHANCEMENT_TYPE] !=
            ENHANCEMENT_TYPES.Replacement,
      ...(formik.values[FIELDS_NAME.ENHANCEMENT_TYPE] ==
         ENHANCEMENT_TYPES.Replacement && {
         deps: [
            formik.values[FIELDS_NAME.ENHANCEMENT_TYPE],
            formik.values[FIELDS_NAME.ITEM],
         ],
      }),
      modifyLabel: true,
      handleLabelModification: options => {
         return options.map(item => ({
            ...item,
            label: `${item.skuNo}-${item.itemName}`,
         }))
      },
      onOptionSelect: option => {
         handleAddReplacementItem(option)
      },
   })

   // Item
   const {
      setSelectedOption: setSelectedItem,
      selectEntity: itemsOptions,
      setSelectEntity: setItemsOptions,
      handleSearchOnOptions: handleSearchItems,
      selectedOption: selectedItem,
      handleSelectAOption: handleSelectAItem,
   } = useAsyncSelect({
      apiFunc: WarehouseInventoryListing,
      // apiFunc: GetDepartmentInventoryListing,
      labelKey: "itemName",
      valueKey: "itemsId",
      params: {
         limit: -1,
         // warehouseItemOption: "configuratorBuild",
         // departmentId: formik.values[FIELDS_NAME.STATION_INVOLVED]?.value,
         // configuratorTrailerId: trailerId,
      },
      modifyLabel: true,
      handleLabelModification: options => {
         return options.map(item => ({
            ...item,
            label: `${item.skuNo}-${item.itemName}`,
         }))
      },
      restrictExecutionOnRender:
         isDetailView || !formik.values[FIELDS_NAME.STATION_INVOLVED],
      deps: [formik.values[FIELDS_NAME.STATION_INVOLVED]],
      onOptionSelect: option => {
         formik.setFieldValue(FIELDS_NAME.ITEM, option)
         formik.setFieldValue(FIELDS_NAME.PRICE, option.itemPrice)
         formik.setFieldValue(FIELDS_NAME.QUANTITY, 0)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const uploadedMediaIDs = async images => {
      if (images) {
         try {
            let res = await handleUploadImages(images)
            return res?.length && res.map(item => item.id)
         } finally {
         }
      }
   }
   const handleUploadImages = async images => {
      let apiCallIns
      let uploadedIds = []

      apiCallIns = images.map(item => {
         return handleMediaUpload(item)
      })

      try {
         setIsLoading(true)
         uploadedIds = (await Promise.allSettled(apiCallIns))
            .filter(item => item.status === "fulfilled")
            .map(id => id.value)
      } finally {
         setIsLoading(false)
      }

      return uploadedIds
   }

   const handleMediaUpload = async file => {
      return initializeMediaUtil(file).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials)
         return await finalizeMediaUtil(credentials?.mediaId)
      })
   }

   const handleSubmit = values => {
      if (
         formik.values[FIELDS_NAME.ENHANCEMENT_TYPE] ==
            ENHANCEMENT_TYPES.Replacement &&
         !Object.values(replacementItems)?.length
      ) {
         return toast.error("Cannot save without enough items details")
      }

      let bodyData = {
         name: values[FIELDS_NAME.NAME],
         itemPrice: values[FIELDS_NAME.SELLING_PRICE],
         configuratorPackagesId: Number(packageId),
         itemsId: values[FIELDS_NAME.ITEM].value,
         quantity: values[FIELDS_NAME.QUANTITY],
         departmentId: values[FIELDS_NAME.STATION_INVOLVED].value,
         enhancementType: values[FIELDS_NAME.ENHANCEMENT_TYPE],
         status: values[FIELDS_NAME.STATUS] ? "ACTIVE" : "INACTIVE",
      }

      if (
         values[FIELDS_NAME.ENHANCEMENT_TYPE] == ENHANCEMENT_TYPES.Replacement
      ) {
         bodyData = {
            ...bodyData,
            enhancementType: values[FIELDS_NAME.ENHANCEMENT_TYPE],
            // replacementItems: (function () {
            removalItems: (function () {
               const arr = []

               for (const key in replacementItems) {
                  arr.push({
                     itemsId: replacementItems[key].itemsId.value,
                     // quantity: Number(replacementItems[key].quantity.value),
                     // oldQuantity: Number(replacementItems[key].quantity.max),
                  })
               }
               return arr
            })(),
         }
      }
      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const handleUpdate = async data => {
      let arr = []
      let payload = { ...data, oldItemsId: previousItemId }

      let userUploadedImages = imagesRef.current.getAllImages()
      Object.values(imagesRef.current).forEach(
         item => item?.getAllImages && arr.push(...item?.getAllImages())
      )

      if (!userUploadedImages.length && !previousImages.length) {
         return toast.error("Upload item images !")
      }

      try {
         setIsLoading(true)

         if (userUploadedImages.length) {
            uploadedMediaIDs(userUploadedImages).then(async res => {
               const apiFormatForImages = res.map(imageId => ({
                  mediaId: imageId,
               }))
               payload.images = [
                  ...(apiFormatForImages?.length && apiFormatForImages),
                  ...(previousImages?.length
                     ? previousImages.map(image => ({
                          mediaId: image.media.id,
                       }))
                     : []),
               ]

               await handleUpdateExecution(id, payload)
               handleModalClose()
               onSuccess()
            })
         } else {
            payload.images = [
               ...(previousImages?.length &&
                  previousImages.map(image => ({
                     mediaId: image.mediaId,
                  }))),
            ]

            await handleUpdateExecution(id, payload)
            handleModalClose()
            onSuccess()
         }
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length) {
         return toast.error("Upload item images !")
      }

      try {
         setIsLoading(true)
         let ids = await uploadedMediaIDs(userUploadedImages)
         let apiFormatForImages = ids.map(imageId => ({
            mediaId: imageId,
         }))
         data.images = [...(apiFormatForImages.length && apiFormatForImages)]

         let res = await CreatePackageItem(data)
         toast.success("Item has been added successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetPackageItemDetails(id)
         setPreviousItemId(res.itemsId)
         formik.setValues({
            [FIELDS_NAME.NAME]: res?.name,
            [FIELDS_NAME.ITEM]: {
               label: res?.item?.itemName,
               value: res?.itemsId,
               skuNo: res?.item.skuNo,
            },

            [FIELDS_NAME.QUANTITY]: res?.quantity,
            [FIELDS_NAME.PRICE]: res?.unitPrice,
            [FIELDS_NAME.SELLING_PRICE]: res?.itemPrice,
            [FIELDS_NAME.STATION_INVOLVED]: {
               label: res?.department?.departmentName,
               value: res?.department?.id,
            },
            [FIELDS_NAME.ENHANCEMENT_TYPE]: res?.enhancementType,
            [FIELDS_NAME.STATUS]: handleStatusConversion(res.status),
         })
         setPreviousImages(res?.images)
         setConfiguratorBuildId(res?.id)
         if (
            res?.enhancementType == ENHANCEMENT_TYPES.Replacement &&
            res?.replacementItems?.length
         ) {
            let obj = {}

            res.replacementItems.forEach((item, indx) => {
               obj = {
                  ...obj,

                  [item.itemsId]: {
                     rootId: item.itemsId,
                     localId: "",
                     dbId: item.itemsId,
                     itemSKU: {
                        name: item.item.skuNo,
                        value: item.item.skuNo,
                        errorMsg: "",
                     },
                     itemName: {
                        name: item.item.itemName,
                        value: item.item.itemName,
                        errorMsg: "",
                     },
                     itemsId: {
                        name: item.itemsId,
                        value: item.itemsId,
                        errorMsg: "",
                     },
                     // quantity: {
                     //    name: item.quantity,
                     //    value: item.quantity,
                     //    max: item.oldQuantity,
                     //    errorMsg: "",
                     // },
                  },
               }
            })
            setReplacementItems(obj)
         }
      } finally {
         setIsFetching(false)
      }
   }

   const handleModalClose = () => {
      formik.resetForm()
      setReplacementItems({})
      onToggleModal()
   }

   const handleAddReplacementItem = itemObj => {
      setReplacementItems(prev => ({
         ...prev,
         [itemObj.itemsId]: {
            rootId: itemObj.itemsId,
            localId: "",
            dbId: "",
            itemSKU: {
               name: itemObj?.skuNo,
               value: itemObj?.skuNo,
               errorMsg: "",
            },

            itemName: {
               name: itemObj?.itemName,
               value: itemObj?.itemName,
               errorMsg: "",
            },
            itemsId: {
               name: itemObj?.itemsId,
               value: itemObj?.itemsId,
               errorMsg: "",
            },
            quantity: {
               name: itemObj.quantity,
               value: itemObj.quantity,
               max: itemObj.quantity,
               errorMsg: "",
            },
         },
      }))
   }

   const handleRemoveReplacementItem = id => {
      let cloneOfCurrentList = { ...replacementItems }
      delete cloneOfCurrentList[id]
      setReplacementItems(cloneOfCurrentList)
   }

   const handleSetReplacementItemName = (evt, id) => {
      setReplacementItems(prev => ({
         ...prev,
         [id]: {
            ...prev[id],
            itemName: {
               ...prev[id]["itemName"],
               value: evt.target.value,
            },
         },
      }))
   }

   const handleSetReplacementItemQty = (evt, id) => {
      setReplacementItems(prev => ({
         ...prev,
         [id]: {
            ...prev[id],
            quantity: {
               ...prev[id]["quantity"],
               value: Number(evt.target.value),
            },
         },
      }))
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         sizeClasses="10"
         backdrop="static"
         modalTitle={
            isDetailView
               ? TITLES.MODAL_DETAILS_TITLE
               : id
               ? TITLES.MODAL_UPDATE_TITLE
               : TITLES.MODAL_ADD_TITLE
         }
         scrollable
      >
         {isFetching ? (
            <FormSkeleton />
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Station Involved
                           </label>
                           {
                              <div className="col-md-9">
                                 {isDetailView ? (
                                    <small className="mx-2">
                                       {
                                          formik.values[
                                             FIELDS_NAME.STATION_INVOLVED
                                          ]?.label
                                       }
                                    </small>
                                 ) : (
                                    <>
                                       <AutoComplete
                                          handleBlur={() =>
                                             setStationsOptions(prev => {
                                                return {
                                                   ...prev,
                                                   page: 1,
                                                   Q: "",
                                                }
                                             })
                                          }
                                          onChange={handleSelectAStation}
                                          onInputChange={handleSearchStations}
                                          placeholder="Search stations"
                                          classNamePrefix="add-new-req"
                                          customStyles={AutoCompleteStyling}
                                          options={stationsOptions?.data}
                                          value={
                                             formik.values[
                                                FIELDS_NAME.STATION_INVOLVED
                                             ]
                                          }
                                          isLoading={stationsOptions?.fetching}
                                          name={FIELDS_NAME.STATION_INVOLVED}
                                          isDisabled={
                                             isDetailView || isUpdating
                                          }
                                       />
                                       <FormikErrorText
                                          formikInstance={formik}
                                          fieldName={
                                             FIELDS_NAME.STATION_INVOLVED
                                          }
                                       />
                                    </>
                                 )}
                              </div>
                           }
                        </Row>
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Name
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 disabled={
                                    !formik.values[FIELDS_NAME.STATION_INVOLVED]
                                 }
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter item name"
                                 name={FIELDS_NAME.NAME}
                                 {...formik.getFieldProps(FIELDS_NAME.NAME)}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.NAME}
                              />
                           </div>
                        </Row>

                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Item
                           </label>
                           <div className="col-md-9">
                              <MainItemModal
                                 formik={formik}
                                 handleSearchItems={handleSearchItems}
                                 handleSelectAItem={handleSelectAItem}
                                 isDetailView={isDetailView}
                                 itemsOptions={itemsOptions}
                                 setItemsOptions={setItemsOptions}
                              />
                           </div>
                        </Row>

                        {/* <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Item
                           </label>
                           <div className="col-md-9">
                              {isDetailView ? (
                                 <small className="mx-2">
                                    {formik.values[FIELDS_NAME.ITEM]?.label}
                                 </small>
                              ) : (
                                 <>
                                    <AutoComplete
                                       isDisabled={
                                          !formik.values[
                                             FIELDS_NAME.STATION_INVOLVED
                                          ]
                                       }
                                       handleBlur={() =>
                                          setItemsOptions(prev => {
                                             return {
                                                ...prev,
                                                page: 1,
                                                Q: "",
                                             }
                                          })
                                       }
                                       onChange={handleSelectAItem}
                                       onInputChange={handleSearchItems}
                                       placeholder="Select item"
                                       classNamePrefix="add-new-req"
                                       customStyles={AutoCompleteStyling}
                                       options={itemsOptions?.data}
                                       value={formik.values[FIELDS_NAME.ITEM]}
                                       isLoading={itemsOptions?.fetching}
                                       name={FIELDS_NAME.ITEM_NAME}
                                    />
                                    <FormikErrorText
                                       formikInstance={formik}
                                       fieldName={FIELDS_NAME.ITEM_NAME}
                                    />
                                 </>
                              )}
                           </div>
                        </Row> */}

                        {/* Item Price */}

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Unit Price ($)
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 disabled={
                                    !formik[FIELDS_NAME.ITEM] && !isDetailView
                                 }
                                 type="number"
                                 min={0}
                                 step="0.01"
                                 inputClass={isDetailView && "input-readonly"}
                                 name={FIELDS_NAME.PRICE}
                                 placeholder=""
                                 {...formik.getFieldProps(FIELDS_NAME.PRICE)}
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.PRICE}
                              />
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Price ($)
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 disabled={!formik.values[FIELDS_NAME.ITEM]}
                                 type="number"
                                 min={0}
                                 step="0.01"
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter item price"
                                 name={FIELDS_NAME.SELLING_PRICE}
                                 {...formik.getFieldProps(
                                    FIELDS_NAME.SELLING_PRICE
                                 )}
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.SELLING_PRICE}
                              />
                           </div>
                        </Row>

                        {/* Item Quantity */}

                        {/* <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Quantity
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 disabled={!formik.values[FIELDS_NAME.ITEM]}
                                 type="number"
                                 min={0}
                                 step="0.01"
                                 max="99999999.99"
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter quantity"
                                 name={FIELDS_NAME.QUANTITY}
                                 {...formik.getFieldProps(FIELDS_NAME.QUANTITY)}
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.QUANTITY}
                              />
                           </div>
                        </Row> */}
                        {/* Gallery */}
                        <Row className="mt-4 mb-3 align-items-start">
                           <label className=" col-md-3  clr-theme-primary">
                              Upload Images
                           </label>
                           <div className="col-md-9">
                              <ImageGallery
                                 isMulti
                                 ref={imagesRef}
                                 previousImages={previousImages}
                                 removePreviousImages={setPreviousImages}
                                 previewOnly={isDetailView}
                                 isDetailView={isDetailView}
                              />
                           </div>
                        </Row>

                        {/* Enhancement Type */}

                        {
                           <Row className="mt-4 mb-3 align-items-center">
                              <label className=" col-md-3 col-form-label">
                                 Enhancement Type
                              </label>
                              <div className="col-md-9">
                                 <div className="d-flex gap-2">
                                    <label className="d-flex align-items-center gap-1">
                                       <TextInput
                                          disabled={
                                             isUpdating ||
                                             isDetailView ||
                                             !formik.values[
                                                FIELDS_NAME.STATION_INVOLVED
                                             ]
                                          }
                                          type="radio"
                                          name={FIELDS_NAME.ENHANCEMENT_TYPE}
                                          value={ENHANCEMENT_TYPES.Addition}
                                          checked={
                                             formik.values[
                                                FIELDS_NAME.ENHANCEMENT_TYPE
                                             ] == ENHANCEMENT_TYPES.Addition
                                          }
                                          onClick={() =>
                                             formik.setFieldValue(
                                                FIELDS_NAME.ENHANCEMENT_TYPE,
                                                ENHANCEMENT_TYPES.Addition
                                             )
                                          }
                                       />
                                       <span>Addition</span>
                                    </label>

                                    <label className="d-flex align-items-center gap-1">
                                       <TextInput
                                          disabled={
                                             isUpdating ||
                                             isDetailView ||
                                             !formik.values[
                                                FIELDS_NAME.STATION_INVOLVED
                                             ]
                                          }
                                          type="radio"
                                          name={FIELDS_NAME.ENHANCEMENT_TYPE}
                                          value={ENHANCEMENT_TYPES.Replacement}
                                          checked={
                                             formik.values[
                                                FIELDS_NAME.ENHANCEMENT_TYPE
                                             ] == ENHANCEMENT_TYPES.Replacement
                                          }
                                          onClick={() =>
                                             formik.setFieldValue(
                                                FIELDS_NAME.ENHANCEMENT_TYPE,
                                                ENHANCEMENT_TYPES.Replacement
                                             )
                                          }
                                       />
                                       <span>Removal</span>
                                    </label>
                                 </div>
                                 {/* Added Item Field */}

                                 {formik.values[FIELDS_NAME.ENHANCEMENT_TYPE] ==
                                    ENHANCEMENT_TYPES.Replacement && (
                                    <EnhancementItemsModal
                                       setIsRefetch={setIsRefetch}
                                       formik={formik}
                                       handleRemoveReplacementItem={
                                          handleRemoveReplacementItem
                                       }
                                       handleSearchItems={
                                          handleSearchReplacementItems
                                       }
                                       handleSelectAItem={
                                          handleSelectAReplacementItem
                                       }
                                       handleSetItemQuantity={
                                          handleSetReplacementItemQty
                                       }
                                       isDetailView={isDetailView}
                                       itemsOptions={replacementItemsOptions}
                                       setItemsOptions={
                                          setReplacementItemsOptions
                                       }
                                       replacementItems={replacementItems}
                                    />
                                 )}
                              </div>
                           </Row>
                        }

                        {/* Status */}

                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Status
                           </label>
                           <div className="col-md-9">
                              <ActiveInactiveSwitch
                                 isChecked={formik.values[FIELDS_NAME.STATUS]}
                                 isDisable={isDetailView}
                                 name={FIELDS_NAME.STATUS}
                                 onChange={e => {
                                    formik.setFieldValue(
                                       FIELDS_NAME.STATUS,
                                       !e.target.checked
                                    )
                                 }}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.STATUS}
                              />
                           </div>
                        </Row>

                        <div className="d-flex justify-content-center mt-3 mt-2">
                           <Button
                              isLoading={isLoading}
                              type={isDetailView ? "button" : "submit"}
                              title={
                                 isDetailView
                                    ? TITLES.DETAILS_BTN_TEXT
                                    : id
                                    ? TITLES.UPDATE_BTN_TEXT
                                    : TITLES.ADD_BTN_TEXT
                              }
                              className="header-button"
                              onClick={isDetailView && handleModalClose}
                           />
                        </div>
                     </Form>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default ManagePackageItem

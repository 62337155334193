import React from "react"

const CustomArrow = props => {
   const { className, icon, onClick } = props
   return (
      <img
         src={icon}
         alt="icon"
         className={`${className} custom-arrow`}
         onClick={onClick}
      />
   )
}
export default CustomArrow

import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Form } from "reactstrap"

import DeleteIcon from "../../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { Modal } from "components/Modal"
import { TextInput } from "components/TextInput"
import { ENHANCEMENT_TYPES, FIELDS_NAME } from "./FormConfig"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"

const COLUMNS = {
   SKU: "SKU",
   ITEM_NAME: "Item Name",
   ACTIONS: "Actions",
   QUANTITY: "Quantity",
}

const EnhancementItemsModal = ({
   setIsRefetch,
   formik,
   isDetailView = false,
   itemsOptions,
   setItemsOptions,
   handleSelectAItem,
   handleSearchItems,
   uniqueKey,
   replacementItems = {},
   handleRemoveReplacementItem,
   handleSetItemQuantity,
}) => {
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleModalToggle = () => {
      setIsModalOpen(!isModalOpen)
   }

   const handleSave = e => {
      e.preventDefault()

      if (isDetailView) {
         return handleModalToggle()
      }

      const items = Object.keys(replacementItems)

      if (
         formik.values[FIELDS_NAME.ENHANCEMENT_TYPE] ==
            ENHANCEMENT_TYPES.Replacement &&
         !items.length
      ) {
         return toast.error("Cannot save without enough items details")
      }


      handleModalToggle()
   }

   return (
      <div>
         <div className="d-flex  gap-2 align-items-center">
            <button
               disabled={!formik.values[FIELDS_NAME.STATION_INVOLVED]}
               onClick={handleModalToggle}
               className="btn btn-primary"
               type="button"
            >
               {isDetailView ? "View Inventory" : "Manage Inventory"}
            </button>
            {Object.keys(replacementItems)?.length ? (
               <small>{`${
                  Object.keys(replacementItems)?.length
               } item(s) selected`}</small>
            ) : null}
         </div>

         <Modal
            isOpen={isModalOpen}
            handleModalToggling={handleModalToggle}
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            size="xl"
            backdrop="static"
         >
            <Form onSubmit={handleSave} className="w-full mx-4 z-3">
               <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                     <small className="fw-bold p-0 m-0">Inventory Items</small>

                     {!isDetailView && (
                        <div>
                           <div
                              className="m-3"
                              style={{
                                 width: "20.813rem",
                              }}
                           >
                              <AutoComplete
                                 handleBlur={() =>
                                    setItemsOptions(prev => {
                                       return {
                                          ...prev,
                                          page: 1,
                                          Q: "",
                                       }
                                    })
                                 }
                                 onChange={option => handleSelectAItem(option)}
                                 onInputChange={handleSearchItems}
                                 placeholder="Select item"
                                 classNamePrefix="add-new-req"
                                 customStyles={AutoCompleteStyling}
                                 options={itemsOptions?.data}
                                 value={null}
                                 isLoading={itemsOptions?.fetching}
                                 isDisabled={isDetailView}
                              />
                           </div>
                        </div>
                     )}
                  </div>
               </div>

               <div>
                  <DataTable
                     className="height-style"
                     data={Object.values(replacementItems)}
                     config={[
                        {
                           title: COLUMNS.SKU,
                           render: data => data.itemSKU.value || "-",
                        },
                        {
                           title: COLUMNS.ITEM_NAME,
                           render: data => data?.itemName?.name || "-",
                        },
                        // {
                        //    title: COLUMNS.QUANTITY,
                        //    render: data => (
                        //       <>
                        //          {!isDetailView ? (
                        //             <TextInput
                        //                max="99999999.99"
                        //                type="number"
                        //                step="0.01"
                        //                placeholder="Qty"
                        //                value={data.quantity.value}
                        //                onChange={e =>
                        //                   handleSetItemQuantity(
                        //                      e.target.value,
                        //                      data.id
                        //                   )
                        //                }
                        //             />
                        //          ) : (
                        //             <span>{data.quantity}</span>
                        //          )}
                        //       </>
                        //    ),
                        // },
                        {
                           title: COLUMNS.ACTIONS,
                           render: data => {
                              return (
                                 <>
                                    {!isDetailView ? (
                                       <>
                                          <img
                                             src={DeleteIcon}
                                             className="pointer"
                                             onClick={() =>
                                                handleRemoveReplacementItem(
                                                   data.rootId
                                                )
                                             }
                                          />
                                       </>
                                    ) : (
                                       "-"
                                    )}
                                 </>
                              )
                           },
                        },
                     ]}
                  />

                  <div className="d-flex justify-content-center mt-3 mt-2">
                     <Button
                        type={"button"}
                        title={isDetailView ? "Done" : "Save"}
                        className="header-button"
                        onClick={handleSave}
                     />
                  </div>
               </div>
            </Form>
         </Modal>
      </div>
   )
}

export default EnhancementItemsModal

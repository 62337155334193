import React, { useState } from "react"
import Slider from "react-slick"

import "./style.scss"

import rightArrowIcon from "../../../../assets/images/right-arrow.png"
import leftArrowIcon from "../../../../assets/images/left-arrow.png"
import CustomArrow from "components/CustomArrow"

const SINGLE_PACKAGE_IMAGE = 1
const MAX_DOTS = 4
const DOT_WIDTH = 16
const MIN_ITEM_LENGTH = 0

const SliderCard = props => {
   const { images = [], name = "", price = 0, isDefault = true } = props

   const [currentSlide, setCurrentSlide] = useState(0)
   const totalSlides = images?.length || 0

   const getVisibleDotsRange = () => {
      if (totalSlides <= MAX_DOTS) return { start: 0, end: totalSlides }

      let start = Math.max(0, currentSlide - Math.floor(MAX_DOTS / 2))
      let end = start + MAX_DOTS
      if (end > totalSlides) {
         end = totalSlides
         start = Math.max(0, end - MAX_DOTS)
      }
      return { start, end }
   }

   const settings = {
      infinite: false,
      dots: totalSlides > SINGLE_PACKAGE_IMAGE,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <CustomArrow icon={leftArrowIcon} />,
      nextArrow: <CustomArrow icon={rightArrowIcon} />,
      afterChange: current => setCurrentSlide(current),
      customPaging: i => (
         <div
            style={{
               width: "16px",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <button
               className={`rounded-circle ${
                  i === currentSlide ? "bg-dark" : "bg-secondary"
               }`}
               style={{
                  width: "5px",
                  height: "5px",
                  transition: "all 0.5s ease",
               }}
            />
         </div>
      ),
      appendDots: dots => {
         const { start } = getVisibleDotsRange()
         const visibleDots = dots.slice(start, start + MAX_DOTS)

         return (
            <div
               className=" mt-2 overflow-hidden position-relative"
               style={{ width: "64px" }}
            >
               <ul
                  className="d-flex justify-content-center align-items-center position-absolute bottom-0"
                  // className="d-flex justify-content-center position-relative"
                  style={{
                     width: "100%",
                     marginBottom: "-20px",
                     transition: "transform 0.5s",
                     left: `${start * DOT_WIDTH}px`,
                     transform: `translateX(-${start * DOT_WIDTH}px)`,
                  }}
               >
                  {visibleDots}
               </ul>
            </div>
         )
      },
   }

   return (
      <div className={`slider-card`}>
         <div className="slider-card-top align-items-start">
            <div className="slider-card-detail">
               <p className="slider-card-detail-name">{name}</p>
               <p className="slider-card-detail-price">${price}</p>
            </div>

            <div className="slider-card-right">
               {isDefault ? (
                  <span className="slider-card-right-badge">Default</span>
               ) : null}
            </div>
         </div>

         <div className="slider-container">
            {images?.length > MIN_ITEM_LENGTH ? (
               <Slider {...settings}>
                  {images.map((item, index) => (
                     <div key={index}>
                        <img
                           src={item.media?.path}
                           alt={`${name || "item"}-${index}`}
                           // width={"200px"}
                           className="slider-card-detail-img"
                        />
                     </div>
                  ))}
               </Slider>
            ) : (
               images.map((item, index) => (
                  <div key={index}>
                     <img
                        src={item.media?.path}
                        alt={`${name || "item"}-${index}`}
                        // width={"200px"}
                        className="slider-card-detail-img"
                     />
                  </div>
               ))
            )}
         </div>
      </div>
   )
}

export default SliderCard

import Config from "Config"
import { useState, useEffect, useRef } from "react"
import direction from "../constants/SortOrder"

export const useLocalPaginatedRequest = ({
   requestFn,
   params = {},
   deps = [],
   searchKey = "Q",
   dataKey = "data",
   totalKey = "total",
   id = null,
   successCallback = () => {},
}) => {
   const [page, setPage] = useState(1)
   const [pageSize, setPageSize] = useState(Config.LIMIT)
   const [searchText, setSearchText] = useState("")
   const [data, setData] = useState(undefined)
   const [total, setTotal] = useState("")
   const [wholeResponse, setWholeResponse] = useState("")
   const [isFetching, setIsFetching] = useState(false)
   const [tableDataChangeLoading, setTableDataChangeLoading] = useState(false)
   const requestAbortController = useRef(null)

   useEffect(() => {
      requestAbortController.current = new AbortController()

      request()

      return () => {
         if (requestAbortController.current) {
            requestAbortController.current.abort()
         }
      }
   }, [pageSize, page, searchText, ...deps])

   const request = () => {
      setData([])
      setIsFetching(true)
      requestFn(
         {
            // Limit: Config.LIMIT,
            limit: pageSize,
            page: page,
            direction: direction.DESC,
            column: "createdAt",
            ...(Object.keys(params).length && params),
            ...(searchText?.trim() && { [searchKey]: searchText }),
         },
         requestAbortController.current.signal,
         id
      )
         .then(res => {
            setWholeResponse(res)
            setData(res[dataKey])
            setTotal(res[totalKey])
            successCallback(res[dataKey])
         })
         .finally(() => {
            setIsFetching(false)
         })
   }

   const handleSearchChange = e => {
      let searchText = e.target.value
      setSearchText(searchText)
      setPage(1)
   }

   const handlePageClick = pageNo => {
      setPage(pageNo)
   }

   const onChangePageSize = event => {
      setPageSize(Number(event.target.value))
      setPage(1)
   }

   return {
      page,
      searchText,
      handleSearchChange,
      handlePageClick,
      pageSize,
      onChangePageSize,
      data,
      total,
      request,
      isFetching,
      setData,
      wholeResponse,
      setTableDataChangeLoading,
      tableDataChangeLoading,
   }
}

import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"

import { DataTable } from "components/DataTable"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { SearchBox } from "components/SearchBox"
import { AutoComplete } from "components/AutoComplete"
import customStyles from "./AutocompleteStyling"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import AdminInventoryViewDetail from "./AdminInventoryViewDetail"
import GlobalQuantityModal from "../../Common/GlobalInventoryViewModal.js/GlobalQuantityModal"

import { WarehouseInventoryListing, WarehouseListing } from "api/api.service"
import Config from "Config"
import { getItemThresholdClass } from "utils/itemThresholdUtil"
import AddNewItemForm from "pages/Common/InvManagementItemsTab/AddNewItemForm"
import { Refresh } from "components/Refresh"
// import Config from "Config"
// :TODO: Need to be change when integrate casita apis
// ******-------------------------------
const DATA_KEY = "data"
const TOTAL_KEY = "total"
// ******-------------------------------

const COLUMNS = {
    CHECK_BOXES: "",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity",
    GLOBAL_QUANTITY: "Global Quantity",
    ACTION: "Action",
}

const Listing = () => {
    let controller = new AbortController()
    let signal = controller.signal
    const [dataRefetch, setDataRefetch] = useState(false)
    const [filters, setFilters] = useState(null)
    const [selectedItemToView, setSelectedItemToView] = useState("")
    const [itemDes, setItemDes] = useState("")
    const [selectedRowID, setSelectedRowID] = useState({
        itemsId: "",
        itemName: "",
        itemSKU: "",
    })
    const [modalStates, setModalStates] = useState({
        viewDetailModal: false,
        globalQuantityModal: false,
        showItemDesModal: false,
    })
    const [warehouseListing, setWarehouseListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })

    const {
        page,
        searchText,
        handleSearchChange,
        handlePageClick,
        pageSize,
        onChangePageSize,
        data,
        total,
        isFetching,
        request,
    } = useLocalPaginatedRequest({
        requestFn: WarehouseInventoryListing,
        params: {
            ...(filters?.warehouse && { warehouseId: filters?.warehouse }),
            ...{ itemType: "General" },
            // ...{ warehouseId: 1},
        },
        deps: [dataRefetch, filters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleCloseModal = () => {
        setModalStates({
            viewDetailModal: false,
            globalQuantityModal: false,
            showItemDesModal: false,
        })
        setSelectedItemToView("")
    }

    // Change handler department select
    const handleSelectOptionWarehouse = (name, option) => {
        let tempObj = [...option]
        let arrTemp = []
        tempObj = tempObj.map(el => arrTemp.push(Number(el.value)))
        setFilters({ ...filters, [name]: arrTemp })
    }

    // department select API call
    useEffect(() => {
        getWarehouseListingData(warehouseListing.page)

        return () => {
            controller.abort()
        }
    }, [warehouseListing.Q])

    // department select API call function
    const getWarehouseListingData = page => {
        handleStateSet(setWarehouseListing, { fetching: true, data: [] })
        WarehouseListing(
            {
                limit: Config.LIMIT,
                ...(warehouseListing.Q && { Q: warehouseListing.Q }),
                page: page,
            },
            signal
        )
            .then(res => {
                setWarehouseListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length === Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.warehouseName,
                        }))
                        return [...test, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setWarehouseListing, { fetching: false })
            })
    }

    // handle select search
    const handleSearch = (value, callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    // handle stateset
    const handleStateSet = (callback = () => {}, keysObj = {}) => {
        callback(prev => {
            return {
                ...prev,
                ...(keysObj && keysObj),
            }
        })
    }

    // handle view detial modal
    const handleSelectedItem = item => {
        setSelectedItemToView(item)
        setModalStates({
            ...modalStates,
            viewDetailModal: true,
        })
    }

    const GlobalQuantityHandler = (id, name, SKU) => {
        setSelectedRowID({
            ...selectedRowID,
            itemsId: id,
            itemName: name,
            itemSKU: SKU,
        })
        setModalStates({
            ...modalStates,
            globalQuantityModal: true,
        })
    }

    const handleOpenDesModal = data => {
        let datamodified = {
            ...data,
            id: data.itemsId,
            itemCategoryName: data.itemsCategory.name,
            itemsCategoryId: data.itemsCategory.id,
        }
        setItemDes(datamodified)
        setModalStates(prev => ({ ...prev, showItemDesModal: true }))
    }

    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} />
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                            <div className="status-search-con wh-im-dpgi-status  me-2 ">
                                <AutoComplete
                                    handleBlur={() =>
                                        setWarehouseListing(prev => {
                                            return {
                                                ...prev,
                                                page: 1,
                                                Q: "",
                                            }
                                        })
                                    }
                                    placeholder="All Warehouse"
                                    customStyles={customStyles.WarehouseIM}
                                    onInputChange={val =>
                                        handleSearch(val, setWarehouseListing)
                                    }
                                    onChange={val =>
                                        handleSelectOptionWarehouse(
                                            "warehouse",
                                            val
                                        )
                                    }
                                    isLoading={warehouseListing?.fetching}
                                    isMulti
                                    classNamePrefix="status-header-search-admin"
                                    options={warehouseListing?.data}
                                />
                            </div>
                            <div className="ad-location-search-box">
                                <SearchBox
                                    inputClasses="vendor-search-input input-search"
                                    labelClass="d-none"
                                    placeholder="Search"
                                    searchIcon={true}
                                    searchIconClass="vendor-table-search-icon"
                                    conClass="vendor-table-search-inner-class"
                                    searchText={searchText}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        tableClasses="ad-warehouse-general-inventory-table"
                        loading={isFetching}
                        config={[
                            {
                                title: COLUMNS.SKU_NO,
                                render: data => {
                                    return (
                                        <>
                                            <div
                                                onClick={() =>
                                                    handleOpenDesModal(data)
                                                }
                                                className="table-clickable-column"
                                            >
                                                {data?.skuNo}
                                            </div>
                                        </>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.ITEM_NAME,
                                render: data => {
                                    return data?.itemName
                                },
                            },
                            {
                                title: COLUMNS.QUANTITY,
                                render: data => {
                                    return data?.quantity
                                },
                            },
                            {
                                title: COLUMNS.GLOBAL_QUANTITY,
                                render: data => {
                                    return (
                                        <div
                                            className={`wh-im-global-quantity  ${getItemThresholdClass(
                                                data?.globalTotal,
                                                data?.majorThreshold,
                                                data?.mediumThreshold,
                                                data?.minorThreshold
                                            )}`}
                                            onClick={e =>
                                                GlobalQuantityHandler(
                                                    data?.itemsId,
                                                    data?.itemName,
                                                    data?.skuNo
                                                )
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            {data?.globalTotal}
                                        </div>
                                    )
                                },
                            },
                            {
                                title: COLUMNS.ACTION,
                                render: data => (
                                    <>
                                        <span
                                            className="text-success pointer"
                                            onClick={() =>
                                                handleSelectedItem(data)
                                            }
                                        >
                                            <img src={ActionEyeIcon} />
                                        </span>
                                        {/* <span className="text-success pointer mx-1 mx-md-3">
                      <img src={PencilIcon} />
                    </span>
                    <span className="text-success pointer">
                      <img src={DeleteIcon} />
                    </span> */}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={modalStates.viewDetailModal}
                handleModalToggling={handleCloseModal}
                sizeClasses="10"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
            >
                <AdminInventoryViewDetail
                    modalCloseHandler={handleCloseModal}
                    inventoryItem={selectedItemToView}
                />
            </Modal>
            <Modal
                isOpen={modalStates.globalQuantityModal}
                handleModalToggling={handleCloseModal}
                sizeClasses="10"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
            >
                <GlobalQuantityModal
                    modalCloseHandler={handleCloseModal}
                    selectedRowData={selectedRowID}
                />
            </Modal>

            {/* <Modal
                isOpen={modalStates.showItemDesModal}
                handleModalToggling={handleCloseModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="5"
            >
                <h3 className="text-center inv-req-popup-heading">{`${itemDes?.itemName} (${itemDes.skuNo})`}</h3>
                <div className="p-4">
                    <h4 className="inventory-description">Product Summary</h4>
                    <p className="mt-1 text-center">{`${
                        itemDes?.description || "-"
                    }`}</p>
                </div>
            </Modal> */}

            <Modal
                isOpen={modalStates.showItemDesModal}
                handleModalToggling={handleCloseModal}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <AddNewItemForm
                    isNative
                    isReadOnly
                    modalCloseHandler={handleCloseModal}
                    title={"Item Details"}
                    data={itemDes}
                />
            </Modal>
        </>
    )
}

export default Listing

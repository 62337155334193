import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import { SearchBox } from "components/SearchBox"

import customStyles from "../Inventory/AutocompleteStyling"
import ActionEyeIcon from "assets/images/icons/feather-eye-icon.png"
import { ScrapReturnForm } from "pages/Common/ScrapReturn"
import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { AutoComplete } from "components/AutoComplete"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import { truncateString } from "utils/commonUtils"
import {
   GetScrapReturnListing,
   GetScrapReturnRequestById,
   GetDepartmentsListing,
} from "api/api.service"
import Config from "Config"
import { Refresh } from "components/Refresh"
import { UserRoles } from "../../../constants"
import TheftForm from "pages/Common/Theft/TheftForm"

const COLUMNS = {
   SKU_NO: "SKU No.",
   ITEM_NAME: "Item Name",
   TYPE: "Type",
   QUANTITY: "Quantity",
   DATE: "Date",
   STATION: "Station Name",
   ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const CHARACTER_LIMIT = 20

const InventoryReqTheftDepartmentListing = ({}) => {
   let controller = new AbortController()
   let signal = controller.signal

   const [toggleModal, setToggleModal] = useState(false)
   const [departmentsFilter, setDepartmentsFilter] = useState(null)
   const [isFetchingRequest, setIsFetchingRequest] = useState(false)
   const [selectedListItem, setSelectedListItem] = useState("")
   const [departmentsListing, setDepartmentsListing] = useState({
      data: [],
      fetching: true,
      page: 1,
      isDataAvailable: true,
   })

   const {
      page,
      data,
      total,
      pageSize,
      isFetching,
      searchText,
      request,
      handleSearchChange,
      handlePageClick,
   } = useLocalPaginatedRequest({
      requestFn: GetScrapReturnListing,
      params: {
         ...{ type: UserRoles.Department },
         requestType: "Theft",
         ...(departmentsFilter?.length && {
            id: departmentsFilter.map(item => item.value).join(),
         }),
      },
      deps: [departmentsFilter],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const handleViewScrapDetails = async (scrapId = null) => {
      if (!scrapId) return

      let paramsObj = {
         type: UserRoles.Department,
      }

      setToggleModal(true)

      try {
         setIsFetchingRequest(true)
         let res = await GetScrapReturnRequestById(scrapId, paramsObj)
         setSelectedListItem(res)
      } catch (err) {
      } finally {
         setIsFetchingRequest(false)
      }
   }

   useEffect(() => {
      handleDepartmentsListing(departmentsListing.page)

      return () => {
         controller.abort()
      }
   }, [departmentsListing.Q])

   const handleSelectDepartments = option => {
      if (typeof option != "object" || !option) return
      setDepartmentsFilter(option)
   }
   const handleDepartmentSearch = (value = "", callback) => {
      if (!value) return

      callback(prev => {
         return {
            ...prev,
            data: [],
            page: 1,
            Q: value,
         }
      })
   }

   const handleDepartmentsListing = page => {
      setDepartmentsListing(prev => {
         return {
            ...prev,
            fetching: true,
         }
      })

      GetDepartmentsListing(
         {
            Limit: Config.LIMIT,
            ...(departmentsListing.Q && { Q: departmentsListing.Q }),
            Page: page,
         },
         signal
      )
         .then(res => {
            setDepartmentsListing(prev => ({
               ...prev,
               fetching: false,
               page: page + 1,
               isDataAvailable: res?.data?.length == Config.LIMIT,
               data: (() => {
                  let test = res?.data?.map(item => ({
                     ...item,
                     value: item.id,
                     label: item.departmentName,
                  }))

                  return [...test]
               })(),
            }))
         })
         .finally(() => {
            setDepartmentsListing(prev => {
               return {
                  ...prev,
                  fetching: false,
               }
            })
         })
   }

   return (
      <>
         <Card className="mb-5">
            <Refresh onClick={request} />
            <CardBody className="p-2 p-md-4">
               <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between  admin-inventory-request-form-fields">
                     <div className="d-flex justify-content-start align-items-center admin-inventory-request-autocomplete">
                        <div className="min-250-w margin-right-handle me-2">
                           <AutoComplete
                              isMulti
                              placeholder="Stations"
                              value={departmentsFilter}
                              customStyles={customStyles.WarehouseIM}
                              handleBlur={() =>
                                 setDepartmentsListing(prev => {
                                    return {
                                       ...prev,
                                       page: 1,
                                       Q: "",
                                    }
                                 })
                              }
                              onInputChange={val =>
                                 handleDepartmentSearch(
                                    val,
                                    setDepartmentsListing
                                 )
                              }
                              options={departmentsListing.data}
                              onChange={val => handleSelectDepartments(val)}
                              closeMenuOnSelect={true}
                              isLoading={departmentsListing?.fetching}
                              classNamePrefix="status-header-search-admin"
                           />
                        </div>
                     </div>
                     <div className="location-search-box ad-search-container">
                        <SearchBox
                           inputClasses="vendor-search-input input-search"
                           labelClass="d-none"
                           placeholder="Search"
                           searchIcon={true}
                           searchIconClass="vendor-table-search-icon"
                           conClass="vendor-table-search-inner-class admin-inventory-request-search-box-responsive"
                           searchText={searchText}
                           onChange={handleSearchChange}
                        />
                     </div>
                  </div>
               </div>
               <DataTable
                  data={data}
                  loading={isFetching}
                  tableClasses="ad-scrapreturn-department-inventory-request-table"
                  config={[
                     {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                           return (
                              <>
                                 <div
                                    onClick={() =>
                                       setInventoryDetailsModal(true)
                                    }
                                 >
                                    {data.skuNo || "-"}
                                 </div>
                              </>
                           )
                        },
                     },
                     {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                           return data?.itemName || "-"
                        },
                     },

                     {
                        title: COLUMNS.QUANTITY,
                        render: data => {
                           return <>{data.quantity || "-"}</>
                        },
                     },
                     {
                        title: COLUMNS.STATION,
                        render: data => {
                           return <>{data?.department?.departmentName || "-"}</>
                        },
                     },
                     {
                        title: COLUMNS.DATE,
                        render: data => {
                           return (
                              convertDateTime({
                                 date: data.theftDate,
                                 customFormat:
                                    dateTimeFormat.casitaCustomDateFormatOnly,
                                 dateOnly: true,
                              }) || "-"
                           )
                        },
                     },
                     {
                        className: "text-center",
                        title: COLUMNS.ACTION,
                        render: data => {
                           return (
                              <span
                                 className="text-success pointer"
                                 onClick={() => handleViewScrapDetails(data.id)}
                              >
                                 <img src={ActionEyeIcon} />
                              </span>
                           )
                        },
                     },
                  ]}
               />
               <Pagination
                  currentPage={page}
                  totalData={total}
                  onPageClick={handlePageClick}
                  rowLimit={pageSize}
               />
            </CardBody>
         </Card>
         <Modal
            isOpen={toggleModal}
            handleModalToggling={() => setToggleModal(false)}
            sizeClasses="10"
         >
            <TheftForm
               data={selectedListItem}
               closeModal={() => setToggleModal(false)}
            />
         </Modal>
      </>
   )
}

export default InventoryReqTheftDepartmentListing

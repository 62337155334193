import Config from "Config"
import React, { useMemo } from "react"
import {
   Pagination as PaginationContainer,
   PaginationItem,
   PaginationLink,
} from "reactstrap"

// import { RowsLimit } from "../RowsLimit"
// import { SortingOptions } from "../../constants"
const MAX_PAGE_VIEW_LIMIT = 4

const Pagination = props => {
   const {
      currentPage,
      totalData,
      rowLimit = Config.LIMIT,
      onPageClick,
      handleChange = () => {},
   } = props
   const totalPages = useMemo(() => {
      return Math.ceil(totalData / rowLimit)
   }, [totalData, rowLimit])

   const handlePageClick = val => {
      if (onPageClick && typeof onPageClick === "function") {
         onPageClick(val)
      }
   }

   const handlePreviousClick = () => {
      if (currentPage > 1) {
         handlePageClick(currentPage - 1)
      }
   }

   const handleNextClick = () => {
      if (currentPage < totalPages) {
         handlePageClick(currentPage + 1)
      }
   }

   const renderPages = () => {
      let pages = []

      let startPage = Math.max(
         1,
         currentPage - Math.floor(MAX_PAGE_VIEW_LIMIT / 2)
      )
      let endPage = Math.min(totalPages, startPage + MAX_PAGE_VIEW_LIMIT - 1)

      if (endPage - startPage < MAX_PAGE_VIEW_LIMIT - 1) {
         startPage = Math.max(1, endPage - MAX_PAGE_VIEW_LIMIT + 1)
      }

      if (startPage > 1) {
         pages.push(
            <PaginationItem key={1}>
               <PaginationLink tag="button" onClick={() => handlePageClick(1)}>
                  1
               </PaginationLink>
            </PaginationItem>
         )

         if (startPage > 2) {
            pages.push(
               <PaginationItem key="ellipsis-start">
                  <PaginationLink tag="button">...</PaginationLink>
               </PaginationItem>
            )
         }
      }

      for (let i = startPage; i <= endPage; i++) {
         pages.push(
            <PaginationItem active={i === currentPage} key={i}>
               <PaginationLink tag="button" onClick={() => handlePageClick(i)}>
                  {i}
               </PaginationLink>
            </PaginationItem>
         )
      }

      if (endPage < totalPages) {
         if (endPage < totalPages - 1) {
            pages.push(
               <PaginationItem key="ellipsis-end">
                  <PaginationLink tag="button">...</PaginationLink>
               </PaginationItem>
            )
         }

         pages.push(
            <PaginationItem key={totalPages}>
               <PaginationLink
                  tag="button"
                  onClick={() => handlePageClick(totalPages)}
               >
                  {totalPages}
               </PaginationLink>
            </PaginationItem>
         )
      }

      return pages
   }

   return (
      <>
         {/* {totalData ? (
                <RowsLimit
                    options={SortingOptions}
                    changeHandler={handleChange}
                />
            ) : null} */}

         {totalPages > 1 && (
            <PaginationContainer className="pagination justify-content-end mb-2 mt-4 pagination-container">
               <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                     previous
                     tag="button"
                     onClick={handlePreviousClick}
                  >
                     <span>Previous</span>
                  </PaginationLink>
               </PaginationItem>
               {renderPages()}

               <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next tag="button" onClick={handleNextClick}>
                     <span>Next</span>
                  </PaginationLink>
               </PaginationItem>
            </PaginationContainer>
         )}
      </>
   )
}

export default Pagination

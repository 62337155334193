import React, { useState } from "react"
import toast from "react-hot-toast"
import { Form } from "reactstrap"

import DeleteIcon from "../../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { Modal } from "components/Modal"
import { TextInput } from "components/TextInput"
import { FIELDS_NAME, VARIATIONS } from "./FormConfig"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import FormikErrorText from "components/FormikErrorText/FormikErrorText"

const COLUMNS = {
   SKU: "SKU",
   ITEM_NAME: "Item Name",
   ACTIONS: "Actions",
   QUANTITY: "Quantity",
}

const DefaultItemModal = ({
   variations,
   formik,
   isDetailView = false,
   itemsOptions,
   setItemsOptions,
   handleSelectAItem,
   handleSearchItems,
}) => {
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleModalToggle = () => {

      if (formik.values[FIELDS_NAME.DEFAULT_ITEM]?.id && !formik.values[FIELDS_NAME.QUANTITY]  ) {
         handleDelete()
      }


      setIsModalOpen(!isModalOpen)
   }

   const handleSave = e => {
      e.preventDefault()
      if (formik.values[FIELDS_NAME.QUANTITY] >  99999999.99) {
         return toast.error("Items quantity exceed")
      }

      if (formik.values[FIELDS_NAME.QUANTITY] <=0) {
         return toast.error("Quantity must be 1 or more")
      }

      if (!formik.errors[FIELDS_NAME.QUANTITY]) {
         if (!formik.values[FIELDS_NAME.DEFAULT_ITEM]?.id) {
            return   toast.error("Cannot save without item.")
    
         }

         handleModalToggle()
      }
    
   }

   const handleDelete = ()=> {
      formik.setFieldValue(
         FIELDS_NAME.DEFAULT_ITEM,
         ""
      )
      formik.setFieldValue(
         FIELDS_NAME.QUANTITY,
         0
      )
   }

   return (
      <div>
         <div className="d-flex  gap-2 align-items-center">
            <button
               disabled={
                  !formik.values[FIELDS_NAME.NAME] ||
                  variations == VARIATIONS.Yes
               }
               onClick={handleModalToggle}
               className="btn btn-primary"
               type="button"
            >
               {isDetailView
                  ? "View Inventory"
                  : !formik.values[FIELDS_NAME.DEFAULT_ITEM]?.id
                  ? "Add Inventory"
                  : "Manage Inventory"}
            </button>
            {formik.values[FIELDS_NAME.DEFAULT_ITEM]?.id && (
               <small>1 item selected</small>
            )}
         </div>

         <Modal
            isOpen={isModalOpen}
            handleModalToggling={handleModalToggle}
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            size="xl"
            backdrop="static"
         >
            <form onSubmit={handleSave} className="w-full mx-4 z-3">
               <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                     <small className="fw-bold p-0 m-0">Inventory Item</small>

                     {!isDetailView && (
                        <div>
                           <div
                              className="m-3"
                              style={{
                                 width: "20.813rem",
                              }}
                           >
                              <AutoComplete
                                 handleBlur={() =>
                                    setItemsOptions(prev => {
                                       return {
                                          ...prev,
                                          page: 1,
                                          Q: "",
                                       }
                                    })
                                 }
                                 onChange={option => handleSelectAItem(option)}
                                 onInputChange={handleSearchItems}
                                 placeholder="Select item"
                                 classNamePrefix="add-new-req"
                                 customStyles={AutoCompleteStyling}
                                 options={itemsOptions?.data}
                                 value={null}
                                 isLoading={itemsOptions?.fetching}
                                 isDisabled={isDetailView}
                              />
                           </div>
                        </div>
                     )}
                  </div>
               </div>

               <div>
                  <DataTable
                     data={
                        formik.values[FIELDS_NAME.DEFAULT_ITEM] && [
                           formik.values[FIELDS_NAME.DEFAULT_ITEM],
                        ]
                     }
                     config={[
                        {
                           title: COLUMNS.SKU,
                           render: data => data.skuNo,
                        },
                        {
                           title: COLUMNS.ITEM_NAME,
                           render: data => data.itemName,
                        },
                        {
                           title: COLUMNS.QUANTITY,
                           render: data => (
                              <>
                                 {!isDetailView ? (
                                    <>
                                       <TextInput
                                          min={1}
                                          type="number"
                                          step="0.01"
                                          placeholder="Qty"
                                          name={FIELDS_NAME.QUANTITY}
                                          {...formik.getFieldProps(
                                             FIELDS_NAME.QUANTITY
                                          )}
                                       />
                                       <FormikErrorText
                                          formikInstance={formik}
                                          fieldName={FIELDS_NAME.QUANTITY}
                                       />
                                    </>
                                 ) : (
                                    <span>
                                       {formik.values[FIELDS_NAME.QUANTITY]}
                                    </span>
                                 )}
                              </>
                           ),
                        },
                        {
                           title: COLUMNS.ACTIONS,
                           render: data => (
                              <>
                                 {!isDetailView ? (
                                    <>
                                       <img
                                          src={DeleteIcon}
                                          className="pointer"
                                          onClick={() => handleDelete()}
                                       />
                                    </>
                                 ) : (
                                    "-"
                                 )}
                              </>
                           ),
                        },
                     ]}
                  />

                  <div className="d-flex justify-content-center mt-3 mt-2">
                     <Button
                        type={"button"}
                        title={isDetailView ? "Done" : "Save"}
                        className="header-button"
                        onClick={handleSave}
                     />
                  </div>
               </div>
            </form>
         </Modal>
      </div>
   )
}

export default DefaultItemModal

import React, { useState, useEffect } from "react"
import { Col, Row, Form } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import {
   GetWarrantyDetails,
   CreateWarranty,
   UpdateWarranty,
} from "../../../api/api.service"
import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig.js"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { ConfiguratorPackagesStatus } from "../../../constants"
import { AutoComplete } from "components/AutoComplete"
import { AutoCompleteStyling } from "pages/Common/AutoCompleteStyling"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"

const TITLES = {
   MODAL_ADD_TITLE: "Add Warranty",
   MODAL_UPDATE_TITLE: "Update Warranty",
   MODAL_DETAILS_TITLE: "Warranty Details",
   ADD_BTN_TEXT: "Save",
   UPDATE_BTN_TEXT: "Update",
   DETAILS_BTN_TEXT: "Done",
}

const WARRANTIES = [
   { value: 5, label: 5 },
   { value: 7, label: 7 },
]

const handleStatusConversion = status => {
   return status == ConfiguratorPackagesStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
   let res = await UpdateWarranty(id, data)
   toast.success("Warranty has been updated successfully !")
}

const ManageWarranties = ({
   isDetailView = false,
   isOpen = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const { trailerId } = useParams()
   const [isLoading, setIsLoading] = useState(false)
   const [status, setStatus] = useState(false)
   const [isFetching, setIsFetching] = useState(false)

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      let bodyData = {
         configuratorTrailerId: Number(trailerId),
         name: values[FIELDS_NAME.NAME],
         price: Number(values[FIELDS_NAME.PRICE]),
         year: Number(values[FIELDS_NAME.YEAR].value),
         status: values[FIELDS_NAME.STATUS],
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const handleUpdate = async data => {
      const payload = {
         ...data,
      }

      try {
         setIsLoading(true)
         await handleUpdateExecution(id, payload)
         handleModalClose()
         onSuccess()
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      try {
         setIsLoading(true)
         let res = await CreateWarranty(data)
         toast.success("Warranty has been added successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetWarrantyDetails(id)

         formik.setValues({
            [FIELDS_NAME.NAME]: res?.name,
            [FIELDS_NAME.PRICE]: res?.price,
            [FIELDS_NAME.STATUS]: res.status,
            [FIELDS_NAME.YEAR]: { value: res.year, label: res.year },
         })
         setStatus(handleStatusConversion(res.status))
      } finally {
         setIsFetching(false)
      }
   }

   const handleModalClose = () => {
      setStatus(false)
      formik.resetForm()
      onToggleModal()
   }
   const handleSetStatus = evt => {
      let { name } = evt.target
      let alteredStatus = !status
      setStatus(alteredStatus)
      formik.setFieldValue(
         name,
         alteredStatus
            ? ConfiguratorPackagesStatus.Active
            : ConfiguratorPackagesStatus.Inactive
      )
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container justify-content-center mb-0"
         sizeClasses="5"
         backdrop="static"
         modalTitle={id ? TITLES.MODAL_UPDATE_TITLE : TITLES.MODAL_ADD_TITLE}
      >
         {isFetching ? (
            <div>
               <FormSkeleton />
            </div>
         ) : (
            <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
               <Row>
                  <Col xs={12}>
                     <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Warranty
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter name"
                                 name={FIELDS_NAME.NAME}
                                 {...formik.getFieldProps(FIELDS_NAME.NAME)}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.NAME}
                              />
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              No. of Years
                           </label>
                           <div className="col-md-9">
                              <AutoComplete
                                 name={FIELDS_NAME.YEAR}
                                 placeholder="Select no. of years"
                                 classNamePrefix="add-new-req"
                                 options={WARRANTIES}
                                 customStyles={AutoCompleteStyling}
                                 value={formik.values[FIELDS_NAME.YEAR]}
                                 onChange={option =>
                                    formik.setFieldValue(
                                       FIELDS_NAME.YEAR,
                                       option || ""
                                    )
                                 }
                                 onBlur={() =>
                                    formik.setFieldTouched(
                                       FIELDS_NAME.YEAR,
                                       true
                                    )
                                 }
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.YEAR}
                              />
                           </div>
                        </Row>

                        <Row className="mt-4 mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Price ($)
                           </label>
                           <div className="col-md-9">
                              <TextInput
                                 min={0}
                                 type="number"
                                 step="0.01"
                                 inputClass={isDetailView && "input-readonly"}
                                 placeholder="Enter price"
                                 name={FIELDS_NAME.PRICE}
                                 {...formik.getFieldProps(FIELDS_NAME.PRICE)}
                              />

                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.PRICE}
                              />
                           </div>
                        </Row>

                        <Row className="mb-3 align-items-center">
                           <label className=" col-md-3 col-form-label clr-theme-primary">
                              Status
                           </label>
                           <div className="col-md-9">
                              <ActiveInactiveSwitch
                                 isChecked={status}
                                 isDisable={isDetailView}
                                 name={FIELDS_NAME.STATUS}
                                 onChange={handleSetStatus}
                              />
                              <FormikErrorText
                                 formikInstance={formik}
                                 fieldName={FIELDS_NAME.STATUS}
                              />
                           </div>
                        </Row>

                        <div className="d-flex justify-content-center mt-3 mt-2">
                           <Button
                              isLoading={isLoading}
                              type={"submit"}
                              title={
                                 id
                                    ? TITLES.UPDATE_BTN_TEXT
                                    : TITLES.ADD_BTN_TEXT
                              }
                              className="header-button"
                              onClick={isDetailView && handleModalClose}
                           />
                        </div>
                     </Form>
                  </Col>
               </Row>
            </div>
         )}
      </Modal>
   )
}

export default ManageWarranties
